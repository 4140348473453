import React from "react";
import {Link} from "react-router-dom";

function AboutStage(props) {
    return (
        <div>
            <h2>{props.title}</h2>
            <div>{props.content}</div>
        </div>
    );
}

export default AboutStage;