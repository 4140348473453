import React from "react"; 
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Footer, Header, MenuBtn} from "./components";
import About from "./containers/About/About";
// import AboutUs from "./containers/About/About-us";
import Contact from "./containers/Contact/Contact";
import Home from "./containers/Home/Home";
// import Option1 from "./containers/InstantWill/Option1";
// import Option2 from "./containers/InstantWillPlus/Option2";
// import ExampleForm from "./containers/ExampleForm/ExampleForm";
// import AboutWills1 from "./containers/AboutWills/About-Wills1";
// import Option3 from "./containers/PremierService/Option3";
// import AboutWills2 from "./containers/AboutWills/About-Wills2";
// import AboutWills3 from "./containers/AboutWills/About-Wills3";
import Testimonials from "./containers/Testimonials/Testimonials";
import Faq from "./containers/Faq/Faq";
import OrderNow from "./containers/OrderNow/OrderNow";
// import ComparePrices from "./containers/ComparePrices/ComparePrices";
import Disclaimer from "./containers/Disclaimer/Disclaimer";
import Terms from "./containers/Terms/Terms";
// import Step1 from "./containers/Steps/Step1";
// import Step2 from "./containers/Steps/Step2";
// import Step3 from "./containers/Steps/Step3";
// import ExampleWill from "./containers/ExampleForm/ExampleWill";
import SiteMap from "./containers/SiteMap/SiteMap";

import ScrollToTop from "./components/ScrollToTop";
import Blog from "./containers/Blog/Blog";
import Service from "./containers/Service/service";
import ContactUs from "./containers/ContactUs/ContactUs";
import SpecialOffer from "./containers/SpecialOffer/SpecialOffer";
import FreeEbooks from "./containers/FreeEbooks/FreeEbooks";
import Traking from "./containers/Traking/Tracking";
import Complaints from "./containers/Complaints/Complaints";




const headerSettings = {
    imageLogoLink: "/images/will_logo.gif",
    strapLineGifLink: "/images/space.gif",
    testimonialTopLink: "http://www.lifetime-wills.com",
    headerBannerLink: "/images/banner-image.png",
    headerContactUS: "/images/call-us-phone.gif",
    testimonialTopImageLink: "/images/will_logo2.jpg"
}

const footerSettings = {
    imageLogo2Link: "/images/will_logo.gif",
}

const topNav = [
    {
        steplink: "/step1.html",
        stepImage: "images/nav_step1.gif",
        bannerNewLink: "images/banner1.gif"
    },
    {
        steplink: "/step2.html",
        stepImage: "images/nav_step2.gif",
        bannerNewLink: "images/banner2.gif"
    },
    {
        steplink: "/step3.html",
        stepImage: "images/nav_step3.gif",
        bannerNewLink: "images/banner3.gif"
    },
]


function App() {
    return (
        <div className="App">
            <Router>
            <ScrollToTop />
                {/* <div className="container"> */}
                    <Header headers={headerSettings}/>
                    {/*<MenuBtn linkAttr={topNav}/>*/}
                    <div className="content index_all" > {/*don't remove index_all it's important for mobile view*/}
                        <Switch>
                            <Route path="/" exact component={() => <Home/>}/>
                            <Route path="/about-divorce.html" exact component={() => <About/>}/>
                            <Route path="/contact.html" exact component={() => <Contact/>}/>
                            {/* <Route path="/Option1.html" exact component={() => <Option1/>}/>
                            <Route path="/Option2.html" exact component={() => <Option2/>}/>
                            <Route path="/Option3.html" exact component={() => <Option3/>}/> */}
                            {/* <Route path="/About-Wills1.html" exact component={() => <AboutWills1/>}/>
                            <Route path="/About-Wills2.html" exact component={() => <AboutWills2/>}/>
                            <Route path="/About-Wills3.html" exact component={() => <AboutWills3/>}/> */}
                            <Route path="/order-now.html" exact component={() => <OrderNow/>}/>
                            <Route path="/testimonials.html" exact component={() => <Testimonials/>}/>
                            <Route path="/contact.html" exact component={() => <Contact/>}/>
                            <Route path="/contact-us.html" exact component={() => <ContactUs/>}/>
                            {/* <Route path="/about-us.html" exact component={() => <AboutUs/>}/> */}
                            <Route path="/faq.html" exact component={() => <Faq/>}/>
                            {/* <Route path="/example-form.html" exact component={() => <ExampleForm/>}/> */}
                            {/* <Route path="/compare-prices1.html" exact component={() => <ComparePrices/>}/> */}
                            <Route path="/disclaimer.html" exact component={() => <Disclaimer/>}/>
                            <Route path="/terms.html" exact component={() => <Terms/>}/>
                            {/* <Route path="/step1.html" exact component={() => <Step1/>}/>
                            <Route path="/step2.html" exact component={() => <Step2/>}/>
                            <Route path="/step3.html" exact component={() => <Step3/>}/> */}
                            {/* <Route path="/example-will.html" exact component={() => <ExampleWill/>}/> */}
                            <Route path="/site-map.html" exact component={() => <SiteMap/>}/>
                            <Route path="/blog.html" exact component={() => <Blog/>}/>
                            <Route path="/divorce-services.html" exact component={() => <Service/>}/>
                            <Route path="/special-offer.html" exact component={() => <SpecialOffer/>}/>
                            <Route path="/free-ebooks.html" exact component={() => <FreeEbooks/>}/>
                            <Route path="/247-tracking.html" exact component={() => <Traking/>}/>
                            <Route path="/complaints.html" exact component={() => <Complaints/>}/>
                        </Switch>
                    </div>
                    <Footer footers={footerSettings}/>
                {/* </div> */}
            </Router>
        </div>
    );
}

export default App;