import React from "react";
import ReactDOM from "react-dom";
import "./styles/wills.css";
import "./styles/custom.css";
import "./styles/header.css";
import "./styles/common.css";
import "./styles/accordion.css";

import App from "./App";

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

