import React from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";
// import AboutStage from "../../components/AboutStage";



function Service() {
    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Will</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Will" />
                </MetaTags>
                <div className="service_page" id="sevice_page"> 
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div className="index_title">
                                <h1 className="page_title">Divorce Services</h1>
                            </div>
                            <div className="row">
                                <div className="col-12" id="qd_section">

                                    {/* Service 1 */}
 
                                    <div className="row promo_item">
                                        <div className="col-7">
                                            <div className="row">
                                                <div className="col d-flex">
                                                    <div className="pr-3">
                                                        <p>
                                                            <img src="/images/services-download.svg" width="60" alt="services" />
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h3>Personalised Service</h3>
                                                        <p className="price">£67 incl VAT</p>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            
                                            <div className="details">
                                                <p>
                                                    If you don’t want the stress of filling in forms or trying to understand legal procedures, you should consider our unique <strong>Personal Case Handling Service.</strong>
                                                </p>
                                                <p>
                                                    Your divorce petition <strong>completed for you</strong>, and <strong>then checked by an experienced lawyer</strong>, before being sent on to you by email or post – and all for just £67!
                                                </p>
                                                <p>
                                                    No other divorce provider offers this quality of service for this price. If, within 30 days of purchase, you find another UK online divorce product that offers the same supervised service as ours, for less money, we will give you a <strong>no-quibble 100% refund – and that’s a PROMISE!</strong>
                                                </p>
                                            </div>
                                            <div className="button_group py-4">
                                                <Link className="btn_1 mr-2" to="/order-now.html">
                                                    <span className="b_inner">Order Now</span> 
                                                </Link>
                                                <Link className="btn_2" to="">Find out more</Link>
                                            </div>
                                            
                                        </div>
                                        <div className="col-3">
                                            <div className="d-flex align-items-center mb-3">
                                                <img src="/images/simone_araujo.png" width="40" alt="services" className="rounded-circle" />
                                                <p className="name m-0">Cherol Edwards, <br /> Great Yarmouth</p>
                                            </div>
                                            <p className="review">
                                                “I was most impressed with the help and advice I received at every step of the way. There is always a REAL person just a phone call away ready to help and advise. I would not hesitate to recommend Quickie Divorce to anyone. I would like to take this opportunity to say a huge thank you to the team for their help in making my divorce quite painless. ”
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <span class="badge-image d-block text-center">
                                                <img class="" data-src="/images/services-badge.svg" alt="Since 2010, 150,000 sold" src="/images/services-badge.svg" width="120"/>
                                                    <span class="over-text text-center">
                                                        <span class="since">since 2000</span>
                                                        <br />
                                                        <span class="amount">64,000</span>
                                                        <br />
                                                        <span class="sold">SOLD</span>
                                                    </span>
                                            </span>
                                        </div>
                                    </div>

                                    {/* Service 2 */}

                                    <div className="row promo_item">
                                        <div className="col-7">
                                            <div className="row">
                                                <div className="col d-flex">
                                                    <div className="pr-3">
                                                        <img src="/images/services-download.svg" width="60" alt="services" />
                                                    </div>
                                                    <div>
                                                        <h3>Personal Plus Service</h3>
                                                        <p className="price">£167 incl VAT</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="details">
                                                <p>
                                                    Did you know that if you win the National Lottery after you get divorced your ex-partner would still be <strong>
                                                    entitled to at least half of it!</strong> Even if you are already divorced, or you already have a separation agreement in place, you must still obtain a Clean Break Order to <strong>legally enforce the terms of the agreement.</strong>
                                                </p>
                                                <p>
                                                    Our <strong>Personal Plus Service</strong> includes the benefits of our <strong>Personal Case Handling Service PLUS a drafted</strong> clean break agreement.
                                                </p>
                                                <p>
                                                    No other online divorce provider offers this service for this price – guaranteed! We believe we’ve produced the 
                                                    <strong>quickest, simplest, best quality online clean break solution available in the UK.</strong>
                                                </p>
                                            </div>
                                            <div className="button_group py-4">
                                                <Link className="btn_1 mr-2" to="/order-now.html">
                                                    <span className="b_inner">Order Now</span> 
                                                </Link>
                                                <Link className="btn_2" to="">Find out more</Link>
                                            </div>
                                            
                                        </div>
                                        <div className="col-3">
                                            <div className="d-flex align-items-center mb-3">
                                                <img src="/images/simone_araujo.png" width="40" alt="services" className="rounded-circle" />
                                                <p className="name m-0">David Wright, <br /> Teignmouth</p>
                                            </div>
                                            <p className="review">
                                                “Right from the first contact, a friendly, helpful and highly efficient service - instant updates available when needed - excellent value for money as well - especially the clean break service. I would recommend Quickie Divorce unreservedly. My case was handled by Sian who was a great help at all times. Now, only a few months down the line, I am a mere 5 weeks away from Decree Absolute and can now move on with my life with a load off my shoulders. ”
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <span class="badge-image d-block text-center">
                                                <img class="" data-src="/images/services-badge.svg" alt="Since 2010, 150,000 sold" src="/images/services-badge.svg" width="120"/>
                                                    <span class="over-text text-center">
                                                        <span class="since">since 2000</span>
                                                        <br />
                                                        <span class="amount">64,000</span>
                                                        <br />
                                                        <span class="sold">SOLD</span>
                                                    </span>
                                            </span>
                                        </div>
                                    </div>


                                    {/* Service 3 */}




                                    <div className="row promo_item">
                                        <div className="col-7">
                                            <div className="row">
                                                <div className="col d-flex">
                                                    <div className="pr-3">
                                                        <img src="/images/services-download.svg" width="60" alt="services" />
                                                    </div>
                                                    <div>
                                                        <h3>Quick Online Service</h3>
                                                        <p className="price">£37 incl VAT</p>  
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="details">
                                                <p>
                                                    Divorce doesn’t have to be costly or painful. <strong>Your divorce petition can be initiated online now for just £37</strong> using our simple and comprehensive online service.
                                                </p>
                                                <p>
                                                    All our services are managed by <strong>experienced lawyers,</strong> and include online legal consultations and guidance that will explain the divorce process in simple, straight forward terms and include step-by-step instructions on what to do at each stage.
                                                </p>
                                                <p>
                                                    No other divorce provider offers this quality of service for this price – Guaranteed! No other divorce provider offers more than 12 hours per day email and telephone support (telephone support applies to "personalised" and "personalised plus" products only).
                                                </p>
                                            </div>
                                            <div className="button_group pt-4">
                                                <Link className="btn_1 mr-2" to="/order-now.html">
                                                    <span className="b_inner">Order Now</span> 
                                                </Link>
                                                <Link className="btn_2" to="">Find out more</Link>
                                            </div>
                                            
                                        </div>
                                        <div className="col-3">
                                            <div className="d-flex align-items-center mb-3">
                                                <img src="/images/simone_araujo.png" width="40" alt="services" className="rounded-circle" />
                                                <p className="name m-0">Alexis Craft, <br /> Gloucestershire</p>
                                            </div>
                                            <p className="review">
                                                “I wasn't really sure how using an on line law firm would work out but I also didn't want all the problems associated with long drawn out legal procedure using expensive solicitors. After an introductory chat on the phone with a very nice and impartial lady I was able to download documents from the website in minutes which were clear and easy to fill in. Any problems I had I simply emailed my support manager and always got prompt and helpful replies.”
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <span class="badge-image d-block text-center">
                                                <img class="" data-src="/images/services-badge.svg" alt="Since 2010, 150,000 sold" src="/images/services-badge.svg" width="120"/>
                                                    <span class="over-text text-center">
                                                        <span class="since">since 2000</span>
                                                        <br />
                                                        <span class="amount">14,000</span>
                                                        <br />
                                                        <span class="sold">SOLD</span>
                                                    </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ContentRight />
                    </div>
                </div>
            </div>
        );
}

export default Service;