import React from "react";
import {Link} from "react-router-dom";
// import $ from "jquery";


// $(document).ready(function(){

//     $('.button_group a').click(function(){
//         console.log("hello 1")

//  setTimeout(function(){
//     $('html,body').animate({
            
//         scrollTop: $("body").offset().top-100},
//         'slow');
//     console.log("world 1")

//  },200)

    


//     });
  
//   });

function Footer(props) {
    return (
        <footer className="footer text-center" id="footer">
            <div className="foot_contact_us">
                <div className="row">
                    <div className="col-8">
                        <p>Confused About Divorce? Call <span>0800 803 0813</span> or drop us a message.</p>
                        {/* Confused about your will call 01656 508 262. Free Help and advice. */}
                    </div>
                    <div className="col-4">
                        <Link className="btn_1" to="/contact.html">
                            <span className="b_inner p-0"></span>
                            Contact Us
                        </Link>
                    </div>
                </div>
                
            </div>
            <div className="foot_menus">
                <div className="row">
                    <div className="col">
                        <Link to="/">
                            {/* <img src={props.footers.imageLogo2Link} alt="Lifetime Wills" border="0"/> */}
                            <img src="images/logo-white.svg" alt="Quickie divorce" border="0" title="Quickie divorce" width="180"/>

                        </Link>
                    </div>
                </div>
                <div className="foot_logo">
                    
                </div>
                <div className="foot_links">
                    
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12 d-flex flex-column align-items-center">
                                <ul className="list-unstyled text-left">
                                    <li>
                                        <h3>GETTING STARTED</h3>
                                    </li>
                                    <li> 
                                        <Link to="/divorce-services.html" className="text-center text-md-left text-lg-left">Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/special-offer.html" className="text-center text-md-left text-lg-left">Special Offer</Link>
                                    </li>
                                    <li>
                                        <Link to="/free-ebooks.html" className="text-center text-md-left text-lg-left">Free eBook</Link>
                                    </li>
                                    <li>
                                        <Link to="/247-tracking.html" className="text-center text-md-left text-lg-left">24 Hour Tracking</Link>
                                    </li>
                                </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center flex-column align-items-center">
                            <ul className="list-unstyled text-left">
                                <li>
                                    <h3 className="text-left text-md-left text-lg-left">HELP & ADVICE</h3>
                                </li>
                                <li>
                                    <Link to="/" className="text-center text-md-left text-lg-left">How To Divorce</Link>
                                </li>
                                <li>
                                    <Link to="/contact.html" className="text-center text-md-left text-lg-left">Divorce Resources</Link>
                                </li>
                                <li>
                                    <Link to="/faq.html" className="text-center text-md-left text-lg-left">FAQ</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us.html" className="text-center text-md-left text-lg-left">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/complaints.html" className="text-center text-md-left text-lg-left">Complaints</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center flex-column align-items-center">
                            <ul className="list-unstyled text-center">
                                <li>
                                    <h3 className="text-center text-md-left text-lg-left">ABOUT US</h3>
                                </li>

                                <li>
                                    <Link to="/about-us.html" className="text-center text-md-left text-lg-left">Why Choose Us</Link>
                                </li>

                                <li>
                                    <Link to="/about-wills.html" className="text-center text-md-left text-lg-left">Testimonials</Link>
                                </li>

                                <li>
                                    <Link to="/testimonials.html" className="text-center text-md-left text-lg-left">Privacy Policy</Link>                            
                                </li>

                                <li>
                                    <Link to="/site-map.html" className="text-center text-md-left text-lg-left">Disclaimer</Link> 
                                </li>

                                <li>
                                    <Link to="/site-map.html" className="text-center text-md-left text-lg-left">Terms & Conditions</Link> 
                                </li>

                                <li>
                                    <Link to="/site-map.html" className="text-center text-md-left text-lg-left">Sitemap</Link>
                                </li>
                            </ul> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="foot_Copy">
                <p className="text-footer-mid">© QUICKIE DIVORCE UK 1998-2022</p>
            </div>            
            <script src="http://www.google-analytics.com/urchin.js" type="text/javascript">
            </script>
            <script type="text/javascript">
                _uacct = "UA-2239206-1";
                urchinTracker();
            </script>
        </footer>
    );
}

export default Footer;