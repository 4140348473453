import React from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";



function About() {
    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Wills" />
                </MetaTags>
                <div className="complaints" id="complaints"> 
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            {/* Title */}
                            <div className="index_title">
                                <h1 className="page_title mb-3">Complaints Procedure</h1>
                            </div>
                            <p>For the purposes of this document a complaint is defined as an expression of dissatisfaction, whether justified or not, from or on behalf of anyone receiving advice or services through Quickie Divorce. The "Complaints Handler" refers to the individual dealing with the complaint and the "Complaints Officer" refers to the individual who is responsible for the complaints procedure and each complainant receiving the correct treatment and correspondence throughout the investigation. The Complaints Officer will usually be the Complaints Handler, however if complaints are made against the Complaints Officer they can appoint a suitable alternative member of staff to handle the complaint.</p>
                            <h3 class="psub_title">Method of Receipt</h3>
                            <p>We aim to resolve all issues at first point of contact and we can be contacted in the following ways:</p>
                            <div className="con-details mb-3" id="con-details">
                                <div className="row equal-cols">
                                    <div className="col-12 col-lg-7 col-md-7">
                                        <div className="address bg_gray p-3">
                                            <h6 className="b_line pb-2">Post</h6>
                                            <p>Quickie Divorce</p>
                                            <p>Fields House</p>
                                            <p>18-21 Old Field Road</p>
                                            <p>Pencoed</p>
                                            <p>Bridgend</p>
                                            <p>CF35 5LJ</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-5 mt-3 mt-lg-0 mt-md-0">
                                        <div className="tele bg_gray mb-3 p-3">
                                            <h6 className="b_line pb-2">Telephone</h6>
                                            <p>	0844 573 9163</p>
                                        </div>
                                        <div className="email bg_gray p-3">
                                            <h6 className="b_line pb-2">Email</h6>
                                            <p>
                                                <a href="mailto:support@quickie-divorce.com">support@quickie-divorce.com</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>You are able to escalate any issues into a formal complaint using any of the above methods or by completing the online form available at <a href={"http://div-qcman.com"} target="_blank" rel="noopener noreferrer" >http://div-qcman.com</a></p>
                            <p>Once you have entered our formal complaints procedure you will receive acknowledgement of your complaint within 5 working days of receipt. We aim to resolve all formal complaints as soon as possible and you will receive a response in relation to this matter within a maximum of four weeks and a final response within a maximum eight weeks from date of receipt.</p>
                            <p>By launching an official complaint your case will be investigated fully by the Complaints Handler and all future correspondence to us must be made in writing (post or email) and all correspondence to you will also be made in writing (post or email). Additionally by entering the complaints procedure all work and services purchased will cease and no further work will be carried out on your case until the determination of the complaints investigation has been reached.</p>
                            <p>Once an investigation is complete and a determination has been reached and provided to yourself you have the opportunity to appeal any decision reached within 14 days of receipt. Once you have appealed this decision your complaint will be reviewed by an alternative complaints handler who will provide you with a final determination within four weeks.</p>
                            <div>
                                <h6 className="psub_title">Cancellation Policy</h6>
                                <p>If you have not waived your right to cancel via your online tracker, you can cancel the service you have purchased at any point during the 14 days immediately after purchase.</p>
                            </div>
                        </div>
                        <ContentRight />
                    </div>
                </div>
            </div>
        );
}

export default About;






