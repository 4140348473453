import React from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";
import AboutStage from "../../components/AboutStage";



function About() {
    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Wills" />
                </MetaTags>
                <div className="about_page" id="about_page"> 
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            {/* Title */}
                            <div className="index_title">
                                <h1 className="page_title mb-3 text-left">Special Offer</h1>
                            </div>
                            <div className="head_para b_line">
                                <p> - Professional, Personalised Will for just £10! (normally £39) – with FREE updates for life AND <strong>a Clean Break Agreement for just £99</strong> </p>
                                <p> -  As a Quickie Divorce customer, you can benefit from two exclusive agreements we have with LifeTime Wills UK and Clean Break UK, worth a total of £55.</p>
                            </div>
                            <div className="main_content mt-3">
                                <div className="content_1 b_line">
                                    <h3 className="psub_title fw_700">Clean Break Agreement for just £99</h3>
                                    <p>Clean Break Agreements (or Consent Orders) are the only way to guarantee that financial deals you and your ex-spouse reach are enforceable by law. Clean Break Orders ensure that your ex-partner cannot go back to court to ask for more money or assets post-divorce</p>
                                    <p>Clean Break Orders help tie up financial matters such as maintenance and division of property, savings, shares, policies, and pensions. They can even settle child maintenance provided the terms are agreed by both parties and the court.</p>
                                </div>
                                <div className="content_2 b_line mt-3">
                                    <h3 className="psub_title fw_700">Lifetime Will for just £10 (normally £39)</h3>
                                    <p>If you’ve decided to get divorced, then you will probably want to change your Will.</p>
                                    <p>A valid Will is the only way for you to determine precisely who will inherit your property and who will act as executor. Your Will is a vital legal document, so it is important to ensure that it’s prepared correctly. All our Wills come with FREE updates for life.</p>
                                    <p>Be confident that your Will is absolutely safe and legally binding for the rest of your life. Don’t go through the repeated trouble and expense of updating your Will whenever your personal circumstances change; LifeTime Wills UK is the only Will writing resource you will ever need.</p>
                                </div>
                            </div>
                            
                            
                        </div>
                        <ContentRight />
                    </div>
                </div>
            </div>
        );
}

export default About;