import {React, useState } from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";
// import AccordionStyle from "../../styles/accordio";



function Faq() {

    const [selected , setSelected] =  useState(null)

    const toggle = (i) => {
        if(selected === i){
            return setSelected(null)
        }
        setSelected(i)
    }

    


    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Wills" />
                </MetaTags>
                <div className="about_page" id="about_page"> 
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            {/* Title */}
                            <div className="index_title">
                                <h1 className="page_title">About Divorce</h1>
                            </div>
                            {/* Accordion */}
                            <div className="accordion">
                                {data.map((item,i) => (
                                    <div className="item">
                                        <div className="title" onClick={() => toggle(i)}>
                                            <h2>{item.question}</h2>
                                        </div>
                                        <div className={ selected === i ? 'answer-content show' : 'answer-content' }>
                                            <p className="mb-0"  dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <ContentRight />
                    </div>
                </div>
            </div>
        );
}

const data = [
    {
        question: 'How long does the divorce process take and what is involved?',
        answer: 'The normal divorce takes between 4 and 5 months. For what is involved have a look at "about divorce" section on this web site or click here to see the process at a glance.'
    },
    {
        question: 'What are the likely costs?',
        answer: 'The Court Fees are £593.'
    },
    {
        question: 'How do I find my local court?',
        answer: 'You can use our FREE Court Locator to find your local court.<a href="/court-locator.html">Simply click here to search</a>'
    },
    {
        question: 'Why are you so cheap?',
        answer: 'We are an expert online divorce service - therefore we have considerably fewer overheads than a high street solicitor - this keeps our costs down to a minimum, allowing us to pass on our savings to our customers.'
    },
    {
        question: 'How long do I have to be married before I can get a divorce?',
        answer: 'You cannot start a petition for divorce unless you have been married for more than one year.'
    },
    {
        question: 'What are the grounds for a divorce?',
        answer: 'Since 1968 there has been just one ground for divorce - irretrievable breakdown of marriage. However, you have to prove irretrievable breakdown in one of five different ways. These are: adultery, unreasonable behaviour, desertion for two years, separation for two years where the other party consents, and separation for five years (no consent needed).'
    },
    {
        question: 'How long do I have to wait before I can apply for the Decree Nisi to be made absolute?',
        answer: '6 weeks and one day. After this you may apply for the decree absolute.'
    },
    {
        question: 'If I have been separated for 2 years am I automatically entitled to a divorce?',
        answer: 'No, you would need the other parties consent, alternatively you could rely on the fact of unreasonable behaviour or 5 years separation whereby consent is not required.'
    },
    {
        question: "What if I don't know the other party’s address?,",
        answer: "Attempt to find out from family & friends. Alternatively, a tracing agent may be able to trace the party's whereabouts."
    },
    {
        question: 'What if the respondent does not return the acknowledgement of service form?',
        answer: 'You would need to request the court allow the court bailiff to personally serve the divorce documents on the respondent. The court bailiff would then swear an affidavit confirming service. Should the respondent still not respond you proceed to the next stage, (the application for special procedure) whereby you would use the sworn affidavit as an exhibit. You will have to pay the court a fixed fee for this service. | After service you must allow 29 days for the respondent to file a defence (called an "answer" in divorce proceedings) and, if they do not, you can then apply for the decree nisi (the first divorce decree). We can provide you with a "Request for Service by Court Bailiff" form.'
    },
    {
        question: 'What if the petitioner does not apply for the Decree Absolute, can the respondent apply?',
        answer: 'Yes, providing 3 months have elapsed and the parties have not reconciled.'
    },
    {
        question: 'What if I cannot find the original Marriage Certificate?',
        answer: 'You can contact the relevant register office providing them with the party’s names and date of marriage. They will provide you with the original marriage certificate for a small fee.'
    },
    {
        question: 'What if the Marriage Certificate is in a foreign language?',
        answer: 'In this case the marriage certificate needs to be translated into English by an expert who would then have it sworn. You then need to lodge this translation and statement with the court alongside other documents.'
    },
    {
        question: 'What if I am on low income or unemployed; do I still have to pay the Court Fee?',
        answer: 'You may be exempt. You would need to complete the fee exemption form, providing evidence of your means. The court staff will make an assessment as to whether you qualify. Click here to access the "Fee Remission" form.'
    },
    {
        question: 'What if the Petitioner (person initiating the divorce) resides in England and Wales and the other party (Respondent) resides abroad; can they still divorce?',
        answer: 'Yes, provided one party is either domiciled or habitually resident (for a year) in England and Wales.'
    },
    {
        question: 'Do I have to agree all financial matters before I start divorce proceedings?',
        answer: "No, you do not - but it is a good idea to do so. If you ignore the financial issues and remarry, you may lose the right to have the matrimonial financial affairs reviewed by the court. Many people do not realise that your ex. can make a claim against your estate after you die. There are special rules, and a claim will not succeed in every case, but it is wise to get an agreement on finances and if appropriate obtain a 'Clean Break' Order (see our Personal Plus Service™ page for more information)."
    },
    {
        question: 'Do I have to agree all arrangements for the children before divorce proceedings?',
        answer: 'As with financial issues, you do not have to agree everything regarding the children but it would be a good idea to do so. To get your divorce you have to show that the arrangements for the children are satisfactory or the best that can be devised in the circumstances. In the context of divorce proceedings you can apply for orders relating to the finances for the children and orders relating to residence and contact but the court is reluctant to grant such orders unless it is absolutely necessary. You will have to show why it is impossible to agree'
    },
    {
        question: 'Does it make any difference if we were married abroad?',
        answer: 'No, but if the marriage certificate is in another language then you need to obtain a notarised translation.'
    },
    {
        question: 'Does it make any difference if either or both of us are foreign nationals?',
        answer: 'No, provided either of you are habitually resident in England and Wales. There are complicated rules and if you are unsure please e-mail us.'
    },
    {
        question: 'Does it make any difference if either of us is resident abroad?',
        answer: 'No, provided the other is habitually resident here.'
    },
    {
        question: 'Can I rely on my own adultery for a divorce?',
        answer: 'Afraid not, in this circumstance you will have to ask your spouse to divorce you.'
    },
    {
        question: "If I want to rely on my spouse's adultery after we are separated will it still count?",
        answer: 'Unfortunately not. In order to successfully file for a divorce on the grounds of adultery, then the adultery must be the reason for you and your spouse having separated.'
    },
    {
        question: 'Can I still take divorce proceedings on the grounds of adultery or unreasonable behaviour even if we continue living together?',
        answer: 'Yes, provided that whilst living in the same house you have lived completely separate lives. This means that you have slept in separate bedrooms, done your own domestic chores, have cooked, eaten and socialised separately and divided all bills.'
    },
    {
        question: "If we want to use the ground of separation by consent, do I have to get my spouse's consent before I start the divorce action?",
        answer: 'No, your spouse will be given an opportunity to consent on the acknowledgement form when he/she receives the papers from the court.'
    },
    {
        question: 'When will my completed divorce petition be delivered to me?',
        answer: 'Currently, 99.97% of all divorce petitions are returned the same day they are completed. We aim to dispatch your divorce petition the same day, if submitted before 5.30pm Monday to Friday, and guarantee to dispatch your completed divorce petition within 24 hours of submission.'
    },
    {
        question: 'I have not received my divorce pack. What do I do? (Hotmail / AOL account users.)',
        answer: 'All divorce packs and emails are sent automatically by our server once a payment has been accepted. Sometimes, our email to you will have been treated as junk mail, more commonly if you have a Hotmail or AOL email account. We recommend that you should always check your Hotmail MSN Junk Email Folder or Yahoo Bulk Folder for any emails from Quickie Divorce UK. Click Here for more details.'
    },
    {
        question: 'What is Sage Pay?',
        answer: 'Credit card transactions for Quickie Divorce UK are processed by Sage Pay. Sage Pay provides very high levels of internet and physical security to ensure sensitive credit/debit card information is never compromised.'
    },


]

export default Faq;