import React from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function goBackWard(e, go){
    e.preventDefault();
    if(go){
        window.location.href = "javaScript:history.back(1)"; //eslint-disable-line
    }
}

function Terms() {
    return (
        <div id="details">
            <MetaTags>
                <title>Terms - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="Terms - Life Time Wills" />
            </MetaTags>
            <h1 className="page_title">Terms &amp; Conditions</h1>
            <p><strong>Terms and Conditions Agreement</strong></p>

            <p align="justify">Lifetime Wills is a trading title of Quickie Divorce Limited. </p>
            <p align="justify">This agreement governs the use of the Lifetime Wills website, including
                all associated and incidental services provided by Lifetime Wills resulting
                from the use of this site. </p>
            <p align="justify"> Lifetime Wills reserves the right, at its discretion, to change or modify
                all or any part of this Agreement at any time, effective immediately upon
                notice published on the website. Your use of the Lifetime Wills website
                constitutes your binding acceptance of these terms and conditions, including
                any changes or modifications made by Lifetime Wills as permitted above.
            </p>
            <p><strong>Lifetime Wills documentation and Information Guide</strong></p>
            <p align="justify"> You may not publish, transmit, transfer or sell, reproduce, create derivative
                works from, distribute, perform, display, or in any way exploit any of
                the Content, in whole or in part, except as expressly permitted in this
                Agreement. </p>
            <p align="justify"> You may download or copy the information and advice contained on the
                site for your own personal use only, provided that you maintain all copyright
                and other notices contained in such Content. </p>
            <p align="justify"> In no event shall Lifetime Wills be liable for any indirect, incidental,
                special or consequential damages arising out of or in any way connected
                with the use of the documents, this website or the support service whether
                based in contract, tort, strict liability or otherwise, except in relation
                to death or personal injury for which no limit shall apply. The liability
                of Lifetime Wills for direct loss arising out of the use of the documents,
                this website, and the support service whether based in contract, tort,
                strict liability or otherwise is limited to the total value of the transactions
                under which the claim arises for any one event or series of connected
                events.</p>
            <p align="justify"> Every endeavour will be taken to ensure that the guidance documentation
                is updated regularly to ensure its accuracy. It is our belief that our
                service provides clients with the necessary documents and information
                required to write a will.
            </p>
            <p><strong>Statutory Rights</strong></p>
            <p> These terms and conditions do not affect your statutory rights.</p>
            <p><strong>Support</strong></p>
            <p align="justify"> Lifetime Wills will provide support via email to help you through the
                process of accessing the documents and begin using them.</p>
            <p align="justify"> We will provide assistance with general queries regarding the completion
                of the relevant documentation, however we are not providing legal advice
                at this stage.</p>
            <p><strong>Cancellation Policy</strong></p>
            <p align="justify"> Clients purchasing the Lifetime Wills package online will not be eligible
                to cancel their order as transactions where the computer software is unsealed
                by the computer immediately on purchase is exempt under the Consumer Protection
                (Distance Selling) Regulations 2000. </p>
            <p><strong><a href="/#" name="refund">Refund Policy</a></strong></p>
            <p align="justify">Any use of our service requires the client to utilize our automated software (where the
                computer software is ‘unsealed’ by the computer immediately on purchase). All transactions of this type
                are exempt under the Consumer Protection (Distance Selling) Regulations 2000.</p>
            <p align="justify">Therefore, ALL FEES ARE NON-REFUNDABLE even if you were to change your mind. However if,
                within 30 days of purchase, you find another product that offers the same quality of service,
                documentation and support as ours, for less money, we will give you a no-quibble 100% refund
                GUARANTEED.</p>
            <p align="justify">By using our services and products you agree to our Terms and Conditions.</p>
            <p><strong>Customer Care/Complaints</strong></p>
            <p align="justify"> We have always prided ourselves in providing a full customer care service
                and it is our intention to deal promptly with all complaints that may
                arise in relation to the service that we provide within 14 days of receipt
                of the complaint. Normally, replies to any complaint would be made by
                email or, if required, we can ensure that we can provide a telephone facility
                to discuss matters of a more complex nature if so required.</p>
            <p><strong>Limitations of Use</strong></p>
            <p align="justify"> You may not use the website or the documents for any other purpose except
                for your own personal use. However, you may use the documents as many
                times as you wish, but we recommend that you check with Lifetime Wills
                regularly to ensure that the documents are the latest versions before
                reuse. </p>
            <p align="justify"> As a condition of your use of this website, you warrant to us that you
                will not use this website for any purpose that is unlawful or prohibited
                by these terms, conditions, and notices.
            </p>
            <p><strong>Limitation of Liability in respect of the Documents</strong></p>
            <p align="justify"> We enclose with this agreement, a disclaimer in respect of our liability
                in relation to this service. Please ensure that it is read carefully as
                the following are the terms upon which we would provide our service to
                you.</p>
            <h5>For your information</h5>
            <p align="justify">Please be aware that any customers who use certain E-Mail accounts (e.g. AOL)
                may receive E-Mails to a ‘bulk folder’ rather than the ‘inbox’.</p>

            <div className="w-100 d-flex justify-content-between">
                <Link to="/" className="fleft btn_1 d-flex align-items-center" onClick={(e) => goBackWard(e, 1)}>
                    <span className="b_inner">&#129144; Back</span>
                </Link>
                {/* <Link className="btn_1" to="/example-form.html" >
                    <span className="b_inner">Next &#10132;</span>

                </Link> */}
            </div>

            {/* <a href="/#" onClick={(e) => goBackWard(e, 1)} >
                <img src="/images/back.gif" name="Image12" border="0" alt="back" />
            </a> */}
        </div>
        );
}

export default Terms;