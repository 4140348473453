import React from "react";
// import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";


function About() {
    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Wills" />
                </MetaTags>
                <div className="about_page" id="about_page"> 
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            {/* Title */}
                            <div className="index_title">
                                <h1 className="page_title">Free eBooks</h1>
                            </div>
                            {/* Books */}

                            <div className="books">
                                 {/* First Book */}

                                <div className="row book">
                                    <div className="col-8">
                                        <p> <strong>The Essential Guide to Divorce </strong> by Dr Charlotte B Price ©2004</p>
                                        <p>This advice-filled guide covers every aspect of divorce, including:</p>
                                        <p>'The 7 Golden Rules of Divorce: Follow them or regret it for the rest of your life!'</p>
                                        <p>Subjects also covered include:</p>
                                        <ul>
                                            <li>Essentials of divorce</li>
                                            <li>Stages in obtaining a divorce</li>
                                            <li>Do it yourself divorce in England and Wales</li>
                                            <li>Mediation</li>
                                            <li>Financial implications of divorce</li>
                                            <li>Financial issues relating to children</li>
                                            <li>Dividing debt and assets at divorce</li>
                                            <li>Wills and divorce</li>
                                            <li>Negative and positive coping</li>
                                            <li>Children and divorce</li>
                                            <li>How will divorce affect the children?</li>
                                            <li>Positive steps you can take after divorce</li>
                                            <li>All about stress</li>
                                            <li>Moving forward after divorce</li>
                                            <li>The divorce is final</li>
                                            <li>What do you do now?</li>
                                            <li>You can control your future</li>
                                            <li>And much, much more…</li>
                                        </ul>
                                    </div>

                                    <div className="col-4">
                                        <div className="book-img d-flex align-item-center h-100">
                                            <img src="/images/services-download.svg" width="100" alt="services" />
                                        </div>
                                    </div>
                                </div>

                                  {/* First Book */}

                                  <div className="row book">
                                    <div className="col-8">
                                        <p>
                                            <strong>The Essential Guide to Consent Orders</strong> by Dr Charlotte B Price ©2004
                                            This advice-filled guide covers every aspect of a Consent Order, including:
                                        </p>
                                        <p>‘The 6 Golden Rules of a Clean Break: How to Avoid the Hidden Financial Traps!’</p>
                                        <p>Subjects also covered include:</p>

                                        <ul>
                                            <li>What Exactly is a Clean Break?</li>
                                            <li>Some Vital Points to Consider</li>
                                            <li>What can a Clean Break Order include?</li>
                                            <li>Advantages and Disadvantages of a Clean Break Order</li>
                                            <li>How do I know if I am eligible for a Clean Break?</li>
                                            <li>How do Consent Orders Work?</li>
                                            <li>Pensions and Inheritance with a Clean Break</li>
                                            <li>Offsetting</li><li>Ending a Relationship</li><li>Wealthier Couples and the Clean Break</li><li>Legal Aspects of the Clean Break Order</li><li>Changes to the Law</li><li>Ancillary Relief Application</li><li>And much, much more…</li></ul>
                                    </div>

                                    <div className="col-4">
                                        <div className="book-img d-flex align-item-center h-100">
                                            <img src="/images/services-download.svg" width="100" alt="services" />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>



                        </div>
                        <ContentRight />
                    </div>
                </div>
            </div>
        );
}

export default About;