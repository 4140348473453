import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import MetaTags from "react-meta-tags";
import './mobile_home_style.css';
import ContentRight from "../../components/ContentRight";

function chnageNextImage(image){
    if(image){
        $("img[name='Image1']").attr("src", "/images/next_over.gif");
    } else {
        $("img[name='Image1']").attr("src", "/images/next.gif");
    }
}
$('.box-container_right box').click(function(){
    $('html,body').animate({
        scrollTop: $("body").offset().top-100},
        'slow');
});
// please don't delete this code form here,this code for homepage mobile view

const css = `
@media screen and (max-width: 767px){
    .content.index_all{
        padding: 0px;
        background: transparent;
    }
    .box-top div:nth-of-type(2){
        
    }
    .promo_item::after{
        display: none;
    }
    .promo_item{
        padding:20px!important;border-radius: 6px!important;margin-bottom: 20px!important;
    }
    .show-md-xs{
        background: transparent;
    }
    div#more_info {
        padding: 20px;
        background: #fff;
        border-radius: 5px;
    }
    .home_page_main_full .index_overflow .box-container_right{
        border-radius: 5px;
        background: #fff;
    }
    .show-md-xs.bg_mobile{
        display: block;
        border-radius: 5px;
        background: #fff;
        padding: 20px!important;
        margin-bottom: 10px;
        max-width: 92%;
        margin: 20px auto;
    }
    .home_page_main_full .index_overflow .box-container_right{
        max-width: 92%;
        margin: 20px auto;
    }
    div#more_info{
        max-width: 92%;
        margin: 20px auto;
    }
    .home_page_main_full .home_why_use_item h2 {
        display: block;
        color: #fff;
        font-size: 36px;
        line-height: 50px;
    }
    .home_why_use_item {
        width: 48%;
        padding: 20px 10px;
        border-radius: 5px;
        background: #fff;
        margin-bottom: 10px;
        box-shadow: 0 0 4px 1px rgba(0,0,0,0.2);
    }
    .bottom_links a{padding: 8px 10px}
    .home_page_main_full .home_why_use h2 {
        color: #e7a20f;
        display: block;
        color: #fff;
        font-size: 36px;
        line-height: 50px;
    }
    .home_page_main_full .home_why_use_item h2 {
        color: #000;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .home_why_use_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }

    /*Container For Mobile*/
    .container {
        max-width: 100%!important;
        padding: 0px;
    }
    .container .content .home_page_main_full .box-top > div{
        padding: 0px 15px;
    }
    .container .content .home_page_main_full .box-top > div.home_why_use {
        padding-top: 40px;
        background: #fabb68;
        padding-bottom: 40px;
    }
}
`

function Home() {
    return (
        <div id="details">
            <style>{css}</style>
            <MetaTags>
                <title>Home - quickie divorce</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="Home - Life Time Wills" />
            </MetaTags>

            {/*Added Sticky Promotion section and added container */}
            <div className="home_page_main_full">
                <div className="index_overflow clearfix">
                    <div className="box-top">
                        <div className="row d-flex justify-content-lg-between">
                            <div className="col-lg-9 col-md-9 col-12">
                                <div className="show-md-xs">
                                    <h2>LifeTime Wills provides three Will services:</h2>                                    
                                    <div className="promo_item">
                                        <div className="promo_item_img">
                                            <img src="/images/services-personalised.svg" alt="" />
                                        </div>
                                        <div className="promo_item_txt">                    
                                            <h4 className="pheader">Instant Will</h4>
                                            <p className="pheader_content">£39<span> (£59 for mirrored Wills)</span></p>
                                            <p>Initiate your Last Will and Testament online now and have it completed and returned
                                                within 48 hours.</p>
                                            <ul>
                                                <li>
                                                    <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                                </li>
                                                <li>
                                                    <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                                </li>
                                                <li>
                                                    <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                                    Just <Link to="/contact.html" >contact us</Link>
                                                </li>
                                                <li>
                                                    <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                                    friendly and knowledgeable
                                                </li>
                                            </ul>
                                            <div className="bottom_links">
                                                <Link to="/order-now.html">Order now</Link>
                                                <Link to="/Option1.html">Instant Will</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="promo_item">
                                        <div className="promo_item_img">
                                            <img src="/images/services-plus.svg" alt="" />
                                        </div>
                                        <div className="promo_item_txt"> 
                                            <h4 className="pheader">Instant Will Plus</h4>
                                            <p className="pheader_content">£69<span> (£99 for mirrored Wills)</span></p>
                                            <p>As above plus your Will expertly proofread and edited by our Will writing professionals
                                                before dispatching by 1st Class Post <strong>within 48 hours</strong>.</p>
                                            <ul>
                                                <li>
                                                    <strong>Reviewed by Experts</strong> - Your Will thoroughly checked by Will writing
                                                    professionals
                                                </li>
                                                <li>
                                                    <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                                </li>
                                                <li>
                                                    <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                                    Just <Link to="/contact.html">contact us</Link></li>
                                                <li>
                                                    <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                                    friendly and knowledgeable
                                                </li>
                                                <li>
                                                    <strong>FREE Postage</strong> - No extra charge for 1st class postage
                                                </li>
                                                <li>
                                                    <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                                </li>
                                                <li>
                                                    <strong>50% Discount</strong> - Half price Will Storage
                                                </li>
                                            </ul>
                                            <div className="bottom_links">
                                                <Link to="/order-now.html">Order now</Link>
                                                <Link to="/Option2.html">Instant Will</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="promo_item">
                                        <div className="promo_item_img">
                                            <img src="/images/special-offer.svg" alt="" />
                                        </div>
                                        <div className="promo_item_txt"> 
                                            <h4 className="pheader">Premier Service</h4>
                                            <p className="pheader_content">£99<span> (£149 for mirrored Wills)</span></p>
                                            <p>As Instant Will Plus product but with the addition of FREE lifetime secure storage of
                                                your Will.</p>
                                            <ul>
                                                <li>
                                                    <strong>Checked by Experts</strong> - Your Will reviewed by Will writing
                                                    professionals
                                                </li>
                                                <li>
                                                    <strong>Advice You Can Trust</strong> - Our legal experts ensure your peace of mind
                                                </li>
                                                <li>
                                                    <strong>FREE Consultation</strong> - Uncertain whether our service is right for you?
                                                    Just <Link to="/contact.html" >contact us</Link></li>
                                                <li>
                                                    <strong>FREE Telephone/Email Support</strong> - Our customer care team are fast,
                                                    friendly and knowledgeable
                                                </li>
                                                <li>
                                                    <strong>FREE Postage</strong> - No extra charge for 1st class postage
                                                </li>
                                                <li>
                                                    <strong>FREE Guide</strong> - ‘The Essential Guide to Inheritance Tax’ (rrp £16.99).
                                                </li>
                                                <li>
                                                    <strong>FREE Storage</strong> - FREE lifetime Will Storage
                                                </li>
                                            </ul>
                                            <div className="bottom_links">
                                                <Link to="/order-now.html">Order now</Link>
                                                <Link to="/Option1.html">Instant Will</Link>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <div className="hidden-md-xs">
                                    <div id="index_title">
                                        {/* <h1 className="index_title">Will Writing - Professional &amp; Affordable Service <span>With Unlimited Updates</span></h1> */}
                                        <h1 className="index_title">Prefer a Quick, Simple and Affordable UK Divorce?</h1>
                                    </div>
                                  
                                    <p>
                                        <strong>Your divorce petition</strong>  can be initiated online now for <strong><u>just £37.</u></strong> Or you can have <strong>all petitions and applications</strong> drafted for you for <strong><u>just £67.</u></strong>
                                    </p>
                                    <p>
                                        There isn't a simpler, more complete divorce package available in the UK. If you use our 3-step process to generate your divorce petition you can <strong>initiate your divorce in less than 3 minutes and save up to £720.</strong>
                                    </p>
                                    <p>
                                        Last year, over 22,000 people in the UK used Quickie-Divorce to initiate their divorce petition, allowing them to get on with their new, happier lives using the UK's most popular low cost online divorce service.
                                    </p>

                                    <p>
                                        Divorce is never painless, but our experienced divorce lawyers can provide all the essential documentation, papers, advice and support you will need to <strong>settle your uncontested divorce quickly</strong> and efficiently with the least amount of stress or expense and without a court appearance.
                                    </p>
                                    </div>
                                    { /*Why Use Wills*/}
                                    <div className="home_why_use">
                                        <h2>Why use Quickie-Divorce?</h2>
                                        <div className="home_why_use_items ">
                                            <div className="home_why_use_item">
                                                <div>
                                                    <img src="../images/selling-points-trofe.svg" alt="" />
                                                </div>
                                                <div>
                                                <Link to=""><h2>Experience &amp; Expertise</h2></Link>
                                                <p>We're the largest provider of will in the UK</p>
                                                </div>
                                                
                                            </div>
                                            <div className="home_why_use_item">
                                                <div>
                                                <img src="../images/selling-points-thumbs-up.svg" alt="" />

                                                </div>
                                                <div>
                                                <Link to=""><h2>Customer Satisfaction</h2></Link>
                                                <p>Click to see testimonials</p>
                                                </div>
                                                
                                            </div> 
                                            <div className="home_why_use_item">
                                                <div>
                                                <img src="../images/selling-points-hand-shake.svg" alt="" />

                                                </div>
                                                <div>
                                                <Link to=""><h2>Established & Trusted</h2></Link>
                                                <p>Reputable name / years of successful online trading</p>
                                                </div>
                                                
                                            </div>
                                            <div className="home_why_use_item">
                                                <div>
                                                <img src="../images/selling-points-magnifier.svg" alt="" />

                                                </div>
                                                <div>
                                                <Link to=""><h2>24/7 <br /> Tracking</h2></Link>
                                                <p> The only online divorce provider with 24/7 Case Tracking.</p>
                                                </div>
                                                
                                            </div>
                                            <div className="home_why_use_item">
                                                <div>
                                                <img src="../images/selling-points-customer-service.svg" alt="" />

                                                </div>
                                                <div>
                                                <Link to=""><h2>Staff</h2></Link>
                                                <p>We employ more trained support staff than any of our competitors</p>
                                                </div>
                                             
                                            </div>
                                            <div className="home_why_use_item">
                                                <div>
                                                <img src="../images/selling-points-iso.svg" alt="" />

                                                </div>
                                                <div>
                                                <Link to=""><h2>ISO Certified</h2></Link>
                                                <p>The only do it yourself will provider in the UK with ISO 9001</p>
                                                </div>
                                                
                                            </div>
                                            <div className="home_why_use_item">
                                                <div>
                                                <img src="../images/plus-points-guarantee.svg" alt="" />
                                                </div>
                                                <div>
                                                <Link to=""><h2>Guarantee</h2></Link>
                                                <p>Unbeatable no-quibble 100% refund</p>
                                                </div>
                                                
                                            </div>
                                            <div className="home_why_use_item">
                                                <div>
                                                <img src="../images/selling-points-building.svg" alt="" />

                                                </div>
                                                <div>
                                                <Link to=""><h2>Stability</h2></Link>
                                                <p>We operate from our own commercial offices</p>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div>
                                            <p>
                                                We believe we have developed the <strong>quickest, simplest, best quality online divorce solutions available in the UK;</strong> and remember there are no forms, courts or solicitors! However, if within 30 days of purchase, you find another UK online divorce product that offers the same quality of service, documentation and support as ours, for a lower cost, we will give you <strong>a no-quibble 100% refund GUARANTEED.</strong>
                                            </p>
                                        </div>
                                        <div className="text-right mb-4">
                                            <Link to = "/about-divorce.html" className="btn_1">Next</Link>
                                        </div>
                                    </div>
                            </div>
                            <ContentRight />
                            
                        </div>

                        



                        {/*Bottom Content*/}

                        <div className="hidden-md-xs">
                            <h2>LifeTime Wills provides three Will services:</h2>
                            <div className="row">
                                <div className="col-12" id="qd_section">

                                    {/* Service 1 */}
 
                                    <div className="row promo_item">
                                        <div className="col-1">
                                            <img src="/images/services-download.svg" width="60" alt="services" />
                                        </div>
                                        <div className="col-5">
                                            <h3>Quick Online Service</h3>
                                            <p className="price">£37 incl VAT</p>
                                            <p className="details">
                                            Up to 1 hour of expert legal advice and all the required Court petitions you will need to settle your uncontested divorce quickly and efficiently with the least amount of stress or expense and without a court appearance.
                                            </p>
                                            <div className="button_group">
                                                <Link className="btn_1 mr-2" to="/order-now.html">
                                                    <span className="b_inner">Order Now</span> 
                                                </Link>
                                                <Link className="btn_2" to="">Find out more</Link>
                                            </div>
                                            
                                        </div>
                                        <div className="col-4">
                                            <div className="d-flex align-items-center mb-3">
                                                <img src="/images/simone_araujo.png" width="40" alt="services" className="rounded-circle" />
                                                <p className="name m-0">Kim Graham</p>
                                            </div>
                                            <p className="review">
                                                “Helped me fill forms correctly checked and explained all the procedures to follow was so helpful and really quick less than a week papers ready for courts I know it is early days in process, but I feel confident in the service I have been given I was quoted over £1500 for divorce then clean break on top this service is great and staff so helpful thanks”
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <span class="badge-image d-block text-center">
                                                <img class="" data-src="/images/services-badge.svg" alt="Since 2010, 150,000 sold" src="/images/services-badge.svg" width="160"/>
                                                    <span class="over-text text-center">
                                                        <span class="since">since 2000</span>
                                                        <br />
                                                        <span class="amount">67,000</span>
                                                        <br />
                                                        <span class="sold">SOLD</span>
                                                    </span>
                                            </span>
                                        </div>
                                    </div>

                                    {/* Service 2 */}

                                    <div className="row promo_item">
                                        <div className="col-1">
                                            <img src="/images/services-download.svg" width="60" alt="services" />
                                        </div>
                                        <div className="col-5">
                                            <h3>Personalised Service</h3>
                                            <p className="price">£67 incl VAT</p>
                                            <p className="details">
                                            All essential petitions and correspondence drafted for you, and then checked by a qualified lawyer, before being sent on to you by email or post - and all for just £67
                                            </p>
                                            <div className="button_group">
                                                <Link className="btn_1 mr-2" to="/order-now.html">
                                                    <span className="b_inner">Order Now</span> 
                                                </Link>
                                                <Link className="btn_2" to="">Find out more</Link>
                                            </div>
                                            
                                        </div>
                                        <div className="col-4">
                                            <div className="d-flex align-items-center mb-3">
                                                <img src="/images/simone_araujo.png" width="40" alt="services" className="rounded-circle" />
                                                <p className="name m-0">Stuart May</p>
                                            </div>
                                            <p className="review">
                                                “I cant believe that I have got so easy and quick Divorce. It took me 6 months but went fine with amazing price. High street solicitor was asking me at lease £1000.00 but DIY quickie divorce help me including my personal expenses for posting all together I spent £100 only. They help you to fill up all the details require and simply you just need to post. I am very thankful and grateful for the service.”
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <span class="badge-image d-block text-center">
                                                <img class="" data-src="/images/services-badge.svg" alt="Since 2010, 150,000 sold" src="/images/services-badge.svg" width="160"/>
                                                    <span class="over-text text-center">
                                                        <span class="since">since 2000</span>
                                                        <br />
                                                        <span class="amount">64,000</span>
                                                        <br />
                                                        <span class="sold">SOLD</span>
                                                    </span>
                                            </span>
                                        </div>
                                    </div>


                                    {/* Service 3 */}


                                    <div className="row promo_item mb-5">
                                        <div className="col-1">
                                            <img src="/images/services-download.svg" width="60" alt="services" />
                                        </div>
                                        <div className="col-5">
                                            <h3>Personal Plus Service</h3>
                                            <p className="price">£167 incl VAT</p>
                                            <p className="details">
                                                All the benefits of our Personalised Service PLUS a clean break agreement and Lifetime Will.
                                            </p>
                                            <div className="button_group">
                                                <Link className="btn_1 mr-2" to="/order-now.html">
                                                    <span className="b_inner">Order Now</span> 
                                                </Link>
                                                <Link className="btn_2" to="">Find out more</Link>
                                            </div>
                                            
                                        </div>
                                        <div className="col-4">
                                            <div className="d-flex align-items-center mb-3">
                                                <img src="/images/simone_araujo.png" width="40" alt="services" className="rounded-circle" />
                                                <p className="name m-0">Ian F</p>
                                            </div>
                                            <p className="review">
                                            “The team helped me through a very difficult time and made the process plain and simple. 5 stars highly recommended thanks”
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <span class="badge-image d-block text-center">
                                                <img class="" data-src="/images/services-badge.svg" alt="Since 2010, 150,000 sold" src="/images/services-badge.svg" width="160"/>
                                                    <span class="over-text text-center">
                                                        <span class="since">since 2000</span>
                                                        <br />
                                                        <span class="amount">14,000</span>
                                                        <br />
                                                        <span class="sold">SOLD</span>
                                                    </span>
                                            </span>
                                        </div>
                                    </div>



                                    {/* Service 4 */}

                                    <div className="row promo_item" id="qd_section">
                                        
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-2 d-flex align-items-center mb-3">
                                                    <img src="/images/special-offer.svg" width="60" alt="services" />
                                                </div>
                                                <div className="col">
                                                    <p className="price">SPECIAL OFFER</p>
                                                    <p className="mb-1">
                                                    As a LifeTime Wills Customer, you can benefit from discounted will storage. 
                                                    </p>
                                                    <a href="/" className="more">More...</a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-2 d-flex align-items-center mb-3">
                                                    <img src="/images/ebook.svg" width="60" alt="services" />
                                                </div>
                                                <div className="col">
                                                    <p className="price">FREE EBOOK</p>
                                                    <p className="mb-1">
                                                        The is an exclusive offer from Quickie Divorce UK for immediate download to your desktop ABSOLUTELY FREE when you buy any divorce service. 
                                                    </p>
                                                    <a href="/" className="more">More...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                    </div>

                        {/*Top Section Cloned for Mobile View*/}
                        <div className="show-md-xs bg_mobile">
                            <div id="index_title">
                                {/* <h1 className="index_title">Will Writing - Professional &amp; Affordable Service <span>With Unlimited Updates</span></h1> */}
                                <h1 className="index_title">Prefer a Quick, Simple and Affordable UK Divorce?</h1>

                            </div>
                            {/* <ul className="sell">
                                <li>Complete your Will from just £39</li>
                                <li>Have your Will completed in under 3 minutes</li>
                                <li>Change your Will whenever you want for FREE</li>
                            </ul> */}
                            <ul className="sell2">
                                <li>Option for your partner to make a mirror Will</li>
                                <li>Have your Will reviewed by experts</li>
                                <li>Will storage provided from only £79</li>
                            </ul>
                            <p>
                                Will writing is something we all put off, yet it could be the most appreciated thing you ever
                                do for your family.
                            </p>
                            <p>
                                Your Last Will and Testament is a vital legal document, so it is important that your Will is
                                prepared correctly. Failure to do so could leave your benefactors to deal with complicated
                                and expensive issues. Last year over <strong>700,000 people died in the UK without a valid Will</strong> and a number of them had their estates passed to their partner’s parents,
                                their siblings and remote relatives against their wishes.
                            </p>
                            <p>
                                Since 2000 LifeTime Wills has made 1,000s of Wills and has dealt with every eventuality.
                                Customers say that they chose our Will service because we are the only UK Will service that
                                offers <strong>high quality, affordable Wills and FREE updates for life</strong>. Don’t go
                                through the repeated trouble and expense of updating your Will whenever your personal
                                circumstances change; this is the only Will writing resource you will ever need.
                            </p>
                            <p>
                                Our process is quick, simple, and affordable. Complete your Will<strong> from just £39</strong>  in less than 3
                                minutes. There is also the option for a mirrored will for your partner and will storage.
                            </p>
                        </div>
                    </div>
                    

                </div>
            </div>
            
            {/*<div id="more_info" className="clearfix">
                <p><strong>Why is a Will important </strong>, click the ‘next’ button…</p>
                <Link to="/about-wills.html"  onMouseOut={() => chnageNextImage(0)} onMouseOver={() => chnageNextImage(1)} >
                    <img name="Image1" border="0" src="/images/next.gif" alt="next >"/></Link>
            </div>*/}
        </div>
    );
}

export default Home;