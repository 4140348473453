import React from "react";
import {Link} from "react-router-dom";

function Header(props) {
    return (
        <div id="header">
            
            <div class="menu-wrapper">
                <header class="fixed-top header d-flex flex-colum align-items-center row m-0 py-2 px-2 container">
                    <label class="menu-icon order-1 col-lg-2 col-2 mb-0" for="menu-btn">
                        <span class="navicon mt-2">
                            {/* <span class="inner">Menu</span> */}
                        </span>
                    </label>

                    <a href="/" title="Brand" class="logo order-1 col-8 col-md-6 col-lg-2 d-flex justify-content-center justify-content-md-start pl-0">
                        <img src="images/logo-white.svg" alt="separation agreement" border="0" width="180" />
                        {/* <img src="images/logo-dark.svg" alt="separation agreement" border="0" width="180" className="phone"/> */}
                    </a>

                    
                        <div class="navbar_contact hidden-xs call order-3 col-2 col-md-6 col-lg-3 d-flex justify-content-end text-light pr-0">
                            <div class="d-block d-lg-none d-md-none">
                                <p>12</p>
                                {/* <img class="" data-src="images/call-us-phone.png" alt="Call Us" title="Call Us" src="images/call-us-phone.png"/> */}
                            </div>

                            <div class="contact-num hidden-xs text-right d-none d-lg-block d-md-block lh_20">
                                <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                        <img src="images/d-call.png" alt="separation agreement" border="0" width="40" />
                                    </div>
                                    <div>
                                        <div class="call-us">call us on</div>
                                        <div class="figure">0800 803 0813</div>
                                        <div class="navbar_link">
                                            <a href={"https://tracker.quickie-divorce.com/login"} title="Login">Login</a>
                                                |
                                            <Link to="/support.html" title="Support">Support</Link>
                                                |
                                            <Link to="/article.html" title="Article">Article</Link>
                                        </div>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                        {/* <img src="images/phone-number.gif" alt="phone number 0800 440 2512" border="0"/> */}
                    
                    <input class="menu-btn" type="checkbox" id="menu-btn" />
                    
                    <ul className="menu order-3 order-lg-2 col-lg-7 col-12 d-flex justify-content-center justify-content-lg-start flex-column flex-md-row mb-lg-1">
                        <li className="nav-item">
                                <Link className="nav-link"  to="/">Home</Link>
                            </li>
                            {/* <li className="menu-lim">|</li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/about-divorce.html" title="About agreement">About Divorce</Link>
                            </li>
                            {/* <li className="menu-lim">|</li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="divorce-services.html" title="Services">Services</Link>
                            </li>
                            {/* <li className="menu-lim">|</li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="contact-us.html" title="Order now">Contact us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/blog.html" title="Blog">Blog</Link>
                            </li>
                    </ul>
                </header>
            </div>

            <div className="header_banner row pt-5 pt-lg-0 pt-md-0">
                <div className="header_banner_img col-4 text-right">

                    <Link to="/">
                        <img src="/images/banner.png" alt="Quickie Divorce" border="0" title="Quickie Divorce"/>
                        {/* <img src={props.headers.headerBannerLink} alt="Lifetime Wills" border="0" title="Lifetime Wills"/> */}
                        {/* <img src="/images/lifetime-wills-white.png" alt="Lifetime Wills" border="0" title="Lifetime Wills"/> */}
                    </Link>


                   
                </div>
                <div className="header_banner_txt col-8 text-lg-right text-md-right text-center">
                    <h1>Need a Divorce?</h1>
                    <p className="text-lg-right text-md-right text-center">
                        We believe we've created <br />
                        the quickest, simplest & best quality <br />
                        divorce solution available in the UK today</p>
                    {/* <h4 className="show_mobile">We have a best will service in uk <span>from Just £39</span></h4> */}
                </div>
            </div>
            
        </div>
    );
}

export default Header;