import React from "react";
import $ from 'jquery';
import 'bootstrap';
import {Link} from "react-router-dom";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import MetaTags from "react-meta-tags";


// popup alert when clicking on links showing an help message
$(function () {
    $('[data-toggle="popover"]').popover();
})

// show this picture on the side meny when landing in the page.
$(document).ready(function (){
    $(".box-img-menu img").attr("src", "../images/picture9.png");
});


class OrderNow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sErrorMsg: "",
            sErrorMsgTitle: "Check the following:",
            objFrmMain: "",
            arrValidDates: ['YY/DD/MM', 'YYMMDD', 'YYYYMMDD', 'YYYY-MM-DD'],
            iFormatDate: 0,
            sErrIsEmpty: " is required.\n",
            sErrFormat: "Err!",
            sErrNotChoosen: " is not choosen.\n",
            sErrValidateTextMinLength1: " must be at least ",
            sErrValidateTextMinLength2: " characters.\n",
            sErrValidateTextMaxLength1: " can be at most ",
            sErrValidateTextMaxLength2: " characters.\n",
            sErrValidateEmail: " is not a valid e-mail address.\n",
            sErrCompareDatesIsNot: " is not ",
            sErrCompareDatesSameAs: "same as",
            sErrCompareDatesDifferent: "different",
            displayNone: "none",
            monthNames: ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            pPrice: {"opt1a" : "39", "opt1b" : "59", "opt2a" : "69", "opt2b" : "99", "opt3a" : "99", "opt3b" : "149"},
            pTitle: {"opt1a" : "Single Will email service",
                "opt1b" : "Mirror Will email service",
                "opt2a" : "Single Will checked & posted",
                "opt2b" : "Mirror Will checked & posted",
                "opt3a" : "Single Will complete service",
                "opt3b" : "Mirror Will complete service"},
            pKi: {
                "opt1a" : "lw_01_iws",
                "opt1b" : "lw_02_iwm",
                "opt2a" : "lw_03_iwps",
                "opt2b" : "lw_04_iwpm",
                "opt3a" : "lw_05_pss",
                "opt3b" : "lw_06_psm"
            },
            popoverOpen: false,
            popoverOpenPlus: false,
            popoverOpenPremierService: false,
            popoverOpenVoucher: false,
            passwordCharsContent: false,
            amount: '',
            product_ki: '',
            product_title: '',
            product_id: ''
        };
        this.togglePopover = this.togglePopover.bind(this);
        this.togglePopoverPlus = this.togglePopoverPlus.bind(this);
        this.togglePopoverPremierService = this.togglePopoverPremierService.bind(this);
        this.togglePopoverVoucher = this.togglePopoverVoucher.bind(this);
        this.closeAllPopover = this.closeAllPopover.bind(this);
        this.togglePasswordCharsContent = this.togglePasswordCharsContent.bind(this);
    }

    togglePopover() {
        this.setState({ popoverOpen: !this.state.popoverOpen })
    }

    togglePopoverPlus() {
        this.setState({ popoverOpenPlus: !this.state.popoverOpenPlus })
    }

    togglePopoverPremierService() {
        this.setState({ popoverOpenPremierService: !this.state.popoverOpenPremierService })
    }

    togglePopoverVoucher() {
        this.setState({ popoverOpenVoucher: !this.state.popoverOpenVoucher })
    }


    togglePasswordCharsContent() {
        this.setState({ passwordCharsContent: !this.state.passwordCharsContent })
    }

    // open the form when selected a package or the opposite when going back to packages list
    closeWillForm(){
        $(".modal").remove();
        if(this.instantWillBox()) {
            $("[name='frmorder']").slideToggle();
            $("#complete_form").toggleClass("d-none");
        }
    }

    // close all popover
    closeAllPopover(){
        this.setState({ popoverOpen: false });
        this.setState({ popoverOpenPlus: false });
        this.setState({ popoverOpenPremierService: false });
        this.setState({ popoverOpenVoucher: false });
        this.setState({ passwordCharsContent: false });
    }

    // When the user starts to type something inside the password field
    validatePassword(sText, sName) {
        var errorMsg = "";

        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if(!sText.match(lowerCaseLetters)) {
            errorMsg += "- Password must contain at least a lower case letter<br/>";
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if(!sText.match(upperCaseLetters)) {
            errorMsg += "- Password must contain at least a capital letter<br/>";
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if(!sText.match(numbers)) {
            errorMsg += "- Password must contain at least a number<br/>";
        }

        // Validate length
        if(sText.length < 8) {
            errorMsg += "- Password must contain at least 8 characters<br/>";
        }
        return errorMsg;
    }

    validateText(sText, sName, iMinLength, iMaxLength)
    {
        sText = $.trim(sText);
        var errorMsg = "";

        if(sText === "") // If empty
        {
            errorMsg = "- " + sName + this.state.sErrIsEmpty;
        }
        else if(sText !== "") // else if to short or to long string
        {
            if(iMinLength !== null && sText.length < iMinLength) {
                errorMsg = "- " + sName + this.state.sErrValidateTextMinLength1 + iMinLength + this.state.sErrValidateTextMinLength2;
            }
            if(iMaxLength !== null && sText.length > iMaxLength)
                errorMsg = "- " + sName + this.state.sErrValidateTextMaxLength1 + iMaxLength + this.state.sErrValidateTextMaxLength2;
        }

        return errorMsg !== "" ? errorMsg + "<br/>" : "";
    }

    // 'clock' on order now page
    componentDidMount() {
        setInterval(() => {
            var thisDate = new Date();
            var year = thisDate.getFullYear();
            var month = thisDate.getMonth();
            var day = thisDate.getDay();
            var hour = thisDate.getHours();
            var min = thisDate.getMinutes();
            var sec = thisDate.getSeconds() < 10 ? '0' + thisDate.getSeconds() : thisDate.getSeconds();

            $(".time").html(day + " " + this.state.monthNames[month] + " " + year + " " + hour + ":" + min + ":" + sec);
        }, 1000)
    }

    validateEmail(sEmail, sName)
    {
        /* emailChecker = verify a valid email */
        let emailChecker   = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
        let trimmedEmail = $.trim(sEmail);
        var errorMsg = "";

        if(sEmail === "")
        {
            errorMsg = "- " + sName + this.state.sErrIsEmpty;
        } else if(sEmail !== "")
        {
            if((!emailChecker.test(trimmedEmail)))
            {
                errorMsg = "- " + sName + this.state.sErrValidateEmail;
            }
        }
        return errorMsg !== "" ? errorMsg + "<br/>" : "";
    }

    // validate the entire form
    validate(e)
    {
        e.preventDefault();
        let parentThis = this;
        let sErrorMsgFinale = "";
        $(".modal").remove();
        this.setState({sErrorMsgTitle: "Check the following:"});
        let form = document.frmMember;
        sErrorMsgFinale += this.validateText(form.firstName.value, "Name", 2, 20);
        sErrorMsgFinale += this.validateText(form.lastName.value, "First name", 2, 20);
        sErrorMsgFinale += this.validateEmail(form.email.value, "Email");
        sErrorMsgFinale += this.validateText(form.address_1.value, "Address", false);
        sErrorMsgFinale += this.validateText(form.postcode.value, "Postcode", false, 10);
        sErrorMsgFinale += this.validatePassword(form.pass.value, "Password", false);
        sErrorMsgFinale += this.validateText(form.pass2.value, "Confirm Password", false);
        sErrorMsgFinale += this.validateText(form.city.value, "City", false, 20);
        sErrorMsgFinale += this.validateText(form.phone.value, "Telephone", false, 14);

        if(form.pass.value !== form.pass2.value)
        {
            sErrorMsgFinale += '- Passwords do not match';
        }

        let stripBrFromMessage = sErrorMsgFinale.replace( /(<([^>]+)>)/ig, '');

        // check if there are any error returned after stripping <br/> tag
        if($.trim(stripBrFromMessage) !== "")
        {
            // If any error it will be displayed in the modal
            this.modalReport(sErrorMsgFinale);
            return false;
        }
        else
        {
            var ip_address = null;
            $.ajax({
            url: "https://api.ipify.org?format=json",
            dataType: 'json',
            async: false,
            success: function(data) {
            ip_address = data.ip;
            }
            });
            //get site_client_id and create new client on QDS
            let endopointQDS = "https://beta.qds.quickie-divorce.com/api/client-order";
            let workloadQDS = {
                'domain': 'lifetime-wills.com',
                'first_name':  form.firstName.value,
                'last_name':  form.lastName.value,
                'password':  form.pass.value,
                'email':  form.email.value,
                'phone':  form.phone.value,
                'address_1': form.address_1.value,
                'address_2': form.address_2.value,
                'postcode': form.postcode.value,
                'notes': form.product_title.value,
                'city':  form.city.value,
                'package_ki':  form.product_ki.value,
                'ip_address': ip_address,
            }

            // prepare error messages text in case some are triggered
            this.setState({sErrorMsgTitle: "Something went wrong!"});
            this.setState({sErrorMsg: "Message could not be sent for unknown reasons! Please, try later and if the problem  persists contact the customer service."});

            // disable buttons and show invite to "wait" messages
            $(".last_submit").attr("disabled","disabled");
            $(".spinner-border").removeClass("d-none");
            $(".butt_back").attr("disabled","disabled");
            $("#payment-gateway-alert").slideDown();

            // API to QDS to create an order and retrieve client_id and order_id
            // to be provided to the next API to the payment gateway
            $.ajax({
                url: endopointQDS,
                method: "POST",
                data: workloadQDS
            }).fail(function(){
                parentThis.modalReport();
                parentThis.restoreButtonsAndAlerts();
            }).done(function(result){
                if(typeof result.data.client_id !== "undefined" && typeof result.data.order_id !== "undefined"){
                    parentThis.sendPaymentRequest(parentThis, result,form);
                } else {
                    parentThis.modalReport();
                    parentThis.restoreButtonsAndAlerts();
                }
            });
        }
    }

    // send data to create a payment request and be redirected to the payment gateway
    sendPaymentRequest(parentThis, result, form){
        // prepare data to be sent through API to the payment request UKDSsecure
        let endpointPG = 'https://beta.ukds.net/api/request-payment';
        let ukdsUrlTokenPG = "https://beta.ukds.net/?token=";
        let pre_vat = form.amount.value / 1.2;
        let workloadPG = {
            company_id: 1,
            site_id: 7,
            site_client_id:  result.data.client_id,
            site_job_number:  result.data.order_id,
            first_name:  form.firstName.value,
            last_name:  form.lastName.value,
            email:  form.email.value,
            tel:  form.phone.value,
            address_1:  form.address_1.value,
            address_2:  form.address_2.value,
            city: form.city.value,
            postcode:  form.postcode.value,
            country:  'GB',
            source:  'lifetime-wills.com',
            currency:  'GBP',
            amount:  form.amount.value,
            pre_vat:  pre_vat,
            vat_amount:  form.amount.value - pre_vat,
            package:  form.product_title.value,
            processed_by:  'WEB',
            assigned_to:  'WEB',
            company_short_name: 'QD',
            securepay_enabled: 1
        }
        
        let paymentReqeustValidationResponseErrorList = "";

        // API to Payment Gateway
        $.ajax({
            url: endpointPG,
            method: "POST",
            data: workloadPG,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).fail(function(){
            parentThis.modalReport();
        }).done(function(result){
            if(result.message === "Success"){
                window.location.href = ukdsUrlTokenPG + result.data.token;
            } else {
                for (var key in result) {
                    paymentReqeustValidationResponseErrorList += result[key] + "<br/>";
                }
                parentThis.modalReport(paymentReqeustValidationResponseErrorList);
            }
        }).always(function(){
            parentThis.restoreButtonsAndAlerts();
        });
    }

    restoreButtonsAndAlerts(){
        $(".last_submit").removeAttr("disabled");
        $(".butt_back").removeAttr("disabled");
        $(".spinner-border").addClass("d-none");
        $("#payment-gateway-alert").slideUp();
    }

    instantWillBox(){
        let sErrorMsg = "";
        var objFrmMain = document.frmorder;
        sErrorMsg += this.validateRadio(objFrmMain.product, "An option", false);

        if(sErrorMsg !== "")
        {
            // If any error the display 'em
            this.modalReport(sErrorMsg);
            return false;
        }
        else
        {
            // Once the package is chosen prepare hidden inputs with values of the package chosen
            // also populate text messages on the form with price and package title
            var optionPosition = $(":radio[name='product']").index($(":radio[name='product']:checked")) + 1;
            var pType = $("[name='product']:checked").val();
            $("[id='inside-will-box']").text($("[name='product']:checked").val());
            $("#p_title").text(this.state.pTitle[pType]);
            $("#p_amount").text(this.state.pPrice[pType]);
            $("#option_num").text(optionPosition);
            this.setState({product_title: this.state.pTitle[pType]});
            this.setState({amount: this.state.pPrice[pType]});
            this.setState({product_ki: this.state.pKi[pType]});
            $(".p_heading").html("<strong>Amount: " + this.state.pPrice[pType] + "£</strong> <span className=\"discount\">(without voucher discount)</span>");
            return true;
        }
    }

    validateRadio(oFormObj, sName)
    {
        var sErrorMsg = "- " + sName + " is not choosen.\n";
        if(oFormObj.length === null && oFormObj.checked)
            sErrorMsg = "";
        else
        {
            for(var i = 0; i < oFormObj.length; i++)
            {
                if(oFormObj[i].checked)
                {
                    sErrorMsg = "";
                    break;
                }
            }
        }
        return sErrorMsg;
    }

    submitForm()
    {
        $("[name='frmMember']").submit();
    }

    modalReport(sErrorMessage){
        let messageReport = '<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">' +
                                '<div class="modal-dialog" role="document">' +
                                    '<div class="modal-content">' +
                                        '<div class="modal-header">' +
                                            '<h5 class="modal-title" id="exampleModalLabel">' + this.state.sErrorMsgTitle + '</h5>' +
                                            '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
                                            '<span aria-hidden="true">&times;</span>' +
                                            '</button>' +
                                        '</div>' +
                                        '<div class="modal-body">' + (sErrorMessage || this.state.sErrorMsg)  + '</div>' +
                                        '<div class="modal-footer">' +
                                        '<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>' +
                                        '</div>' +
                                    '</div>' +
                                '</div>' +
                            '</div>';
        $("body", window.parent.document).append(messageReport);
        $('#exampleModal').modal('show');
    }

    render () {
        const { popoverOpen } = this.state;
        const { popoverOpenPlus } = this.state;
        const { popoverOpenPremierService } = this.state;
        const { popoverOpenVoucher } = this.state;
        const { passwordCharsContent } = this.state;
        return (
            <div id="details" onClick={this.closeAllPopover}>
                <MetaTags>
                    <title>Order Now - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="Order Now - Life Time Wills" />
                </MetaTags>
                <h1 className="page_title">Order Your Will Now</h1>
                <form name="frmorder" method="get" action="credentials.php">
                    <p className="headingP">Please select the option you want to purchase and click 'submit'.</p>
                    <table>
                        <tbody>
                        <tr>
                            <td><img src="/images/title_opt1.gif" alt="Lifetime Wills Option 1"/></td>
                            <td>
                                <Popover
                                    placement="bottom"
                                    isOpen={popoverOpen}
                                    target="mypopover"
                                    toggle={this.togglePopover}>
                                    <PopoverHeader>Instant Will</PopoverHeader>
                                    <PopoverBody>
                                        <ul><li><strong>Comprehensive Guidance Notes</strong> – No other service provides such comprehensive guides.</li>
                                            <li><strong>Quality Advice</strong> - Be confident that the procedure you are following is appropriate and legally correct.</li>
                                            <li><strong>Free Consultation</strong> – If you are uncertain as to whether our service is right for you please contact us.</li>
                                            <li><strong>Email / Phone Support</strong> – FREE help and advice throughout the whole process.</li>
                                            <li><strong>Free Updates & Upgrades</strong> – Immediate notification/upgrade of law or documentation requirements.</li>
                                            <li><strong>Free Financial Advice</strong> – Free advice on any financial aspect.</li>
                                            <li><strong>Free Guide</strong> - “Essential Guide to Inheritance Tax” Book (rrp £16.99).</li>
                                        </ul>
                                    </PopoverBody>
                                </Popover>
                                <a href="#/" id="mypopover" className="popoverBox primary">Click Here </a> to view package summary
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="5" colSpan="3"></td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="backcell2">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="product" value="opt1a"
                                           id="flexRadioDefault7"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault7">
                                        <strong>£39&nbsp;&nbsp;Single
                                            Will email service</strong>
                                    </label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2" className="backcell2">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="product" value="opt1b"
                                           id="flexRadioDefault2"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        <strong>£59&nbsp;&nbsp;Mirror
                                            Will email service</strong>
                                    </label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="25" colSpan="3"></td>
                        </tr>
                        <tr>
                            <td><img src="/images/title_opt2.gif" alt="Lifetime Wills Option 2"/></td>
                            <td>
                                <Popover
                                    placement="bottom"
                                    isOpen={popoverOpenPlus}
                                    target="instantWillPlus"
                                    toggle={this.togglePopoverPlus}>
                                    <PopoverHeader>Instant Will Plus</PopoverHeader>
                                    <PopoverBody>
                                        <ul><li><strong>Reviewed by Experts</strong> - Your Will is checked by experts.</li>
                                            <li><strong>Comprehensive Guidance Notes</strong> – No other service provides such comprehensive guides.</li>
                                            <li><strong>Quality Advice</strong> - Be confident that the procedure you are following is appropriate and legally correct.</li>
                                            <li><strong>Free Consultation</strong> – If you are uncertain as to whether our service is right for you please contact us.</li>
                                            <li><strong>Email / Phone Support</strong> – FREE help and advice throughout the whole process.</li>
                                            <li><strong>Free Updates & Upgrades</strong> – Immediate notification/upgrade of law or documentation requirements.</li>
                                            <li><strong>Free Postage</strong> – Unlike other services, we won’t charge extra to post you your Will.</li>
                                            <li><strong>Free Financial Advice</strong> – Free advice on any financial aspect.</li>
                                            <li><strong>Free Guide</strong> - “Essential Guide to Inheritance Tax” Book (rrp £16.99).</li>
                                            <li><strong>Free Guide</strong> - Save money on your Will storage.</li></ul>
                                    </PopoverBody>
                                </Popover>

                                <a  href="#/" id="instantWillPlus"
                                   className="popoverBox primary">Click Here </a> to view package summary
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="5" colSpan="3"></td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="backcell2">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="product" value="opt2a"
                                           id="flexRadioDefault3"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        <strong>£69&nbsp;&nbsp;Single
                                            Will checked &amp; posted</strong>
                                    </label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="backcell2">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="product" value="opt2b"
                                           id="flexRadioDefault4"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        <strong>£99&nbsp;&nbsp;Mirror
                                            Will checked &amp; posted</strong>
                                    </label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="25" colSpan="3"></td>
                        </tr>
                        <tr>
                            <td><img src="/images/title_opt3.gif" alt="Lifetime Wills Option 3"/></td>
                            <td>
                                <Popover
                                    placement="bottom"
                                    isOpen={popoverOpenPremierService}
                                    target="instant-will-premier-service"
                                    toggle={this.togglePopoverPremierService}>
                                    <PopoverHeader>Premier Service</PopoverHeader>
                                    <PopoverBody>
                                        <ul><li><strong>Reviewed by Experts</strong> - Your Will is checked by experts.</li>
                                            <li><strong>Comprehensive Guidance Notes</strong> – No other service provides such comprehensive guides.</li>
                                            <li><strong>Quality Advice</strong> - Be confident that the procedure you are following is appropriate and legally correct.</li>
                                            <li><strong>Free Consultation</strong> – If you are uncertain as to whether our service is right for you please contact us.</li>
                                            <li><strong>Email / Phone Support</strong> – FREE help and advice \r          throughout the whole process.</li>
                                            <li><strong>Free Updates & Upgrades</strong> – Immediate notification/upgrade of law or documentation requirements.</li>
                                            <li><strong>Free Postage</strong> – Unlike other services, we won’t charge extra to post you your Will.</li>
                                            <li><strong>Free Financial Advice</strong> – FREE advice on any financial aspect.</li>
                                            <li><strong>Free Guide</strong> - “Essential Guide to Inheritance Tax” Book (rrp £16.99).</li>
                                            <li> <strong>Free Storage</strong> - FREE life time Will storage.</li>
                                        </ul>
                                    </PopoverBody>
                                </Popover>

                                <a href="#/"
                                   title="Premier Service"
                                   id="instant-will-premier-service"
                                   className="popoverBox primary">Click Here </a> to view package summary
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="5" colSpan="3"></td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="backcell2">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="product" value="opt3a"
                                           id="flexRadioDefault5"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault5">
                                        <strong>£99&nbsp;&nbsp;Single
                                            Will complete service</strong>
                                    </label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>

                            <td colSpan="2" className="backcell2">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="product" value="opt3b"
                                           id="flexRadioDefault6"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault6">
                                        <strong>£149&nbsp;&nbsp;Mirror
                                            Will complete service</strong>
                                    </label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="25" colSpan="3"></td>
                        </tr>
                        <tr>
                            <td height="5" colSpan="3"></td>
                        </tr>
                        </tbody>
                    </table>
                    <p><font color="#FF0000">* Wills made by Lifetime Wills are only valid in England and Wales </font>
                    </p>
                    <Popover
                        placement="bottom"
                        isOpen={popoverOpenVoucher}
                        target="voucher-alert"
                        toggle={this.togglePopoverVoucher}>
                        <PopoverHeader>Voucher Code</PopoverHeader>
                        <PopoverBody>
                            <p>To claim your discounted Will simply select an option and click 'Submit'. Then enter your unique voucher code when requested.</p><p>
                            NOTE: If you use a voucher code the cost will be discounted automatically.</p>
                        </PopoverBody>
                    </Popover>
                    <a href="#/"
                       title="Voucher Code"
                       id="voucher-alert"
                       className="popoverBox">
                           {/* Click Here if you
                        have a voucher code */}
                         </a>
                        <div>
                        <input type="button" name="SubmitOptionChoise" id="SubmitOptionChoice" value="Submit"
                           className="but_submit btn_3 mt-3" onClick={this.closeWillForm.bind(this)} />
                        </div>
                    
                </form>
                <div id="complete_form" className="d-none">
                    <div className="row">
                        <div className="col-md-8">
                            <form name="frmMember" method="post" action="">
                            <table width="100%" border="0" cellSpacing="1" cellPadding="4" className="board1">
                                <tbody>
                                <tr>
                                    <th colSpan="3">LifeTime Wills Order Form</th>
                                </tr>

                                <tr>
                                    <td colSpan="2" className="p_backcell"><strong>You are ordering:</strong>&nbsp;Option <span id="option_num"> 2</span> &nbsp;~&nbsp;<span id="p_title"> Single Will Checked Service</span>
                                        <input type="hidden" name="product_title"
                                               value={ this.state.product_title } />
                                        <input type="hidden" name="product_ki" value={ this.state.product_ki } />
                                        <input type="hidden" name="amount" value={ this.state.amount } />
                                        <input type="hidden" name="qty" value="1" />
                                    </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="p_backcell p_heading">Single Will checked &amp; posted
                                        at <strong>£69</strong>
                                        <span className="discount"> (without voucher code)</span></td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="p_backcell"><strong>Payment Method:</strong> Credit / Debit
                                        Card <input type="hidden" name="payment_type" value="credit_card" /></td>
                                </tr>
                                <tr>
                                    <td width="115" height="10" className="p_backcell" colSpan="3"></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell" height="10"></td>
                                    <td className="p_backcell" colSpan="2"></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="p_backcell">Please complete the form below:</td>
                                </tr>
                                <tr>
                                    <td className="p_backcell">First name *:</td>
                                    <td className="p_backcell">
                                        <input name="firstName" type="text" className="fld1 form-control" id="firstName" size="20" maxLength="40" />
                                    </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell">Last name *:</td>
                                    <td className="p_backcell">
                                        <input name="lastName" type="text" className="fld1 form-control" id="lastName" size="20" maxLength="40" />
                                    </td>

                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell">Email *:</td>
                                    <td className="p_backcell"><input name="email" type="text" className="fld1 form-control" id="email"
                                                                      size="30" maxLength="80" />
                                        </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <Popover
                                        placement="bottom"
                                        isOpen={passwordCharsContent}
                                        target="passwordCharsContentVerification"
                                        toggle={this.togglePasswordCharsContent}>
                                        <PopoverHeader>A password must have</PopoverHeader>
                                        <PopoverBody>
                                            <ul><li>at least 8 chars</li>
                                                <li>a capital letter</li>
                                                <li>a lower case letter</li>
                                                <li>a number</li>
                                            </ul>
                                        </PopoverBody>
                                    </Popover>
                                    <td className="p_backcell">Password *:</td>
                                    <td className="p_backcell">
                                        <input name="pass" type="password" autoComplete="on" className="fld1 form-control" id="pass"
                                                                      size="20" maxLength="40" />
                                        </td>
                                    <td className="p_backcell"><a href="#/" id="passwordCharsContentVerification" className="popoverBox">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-question-circle mr-4" viewBox="0 0 16 16">
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                            <path
                                                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
                                        </svg>
                                    </a></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell">Retype Password *:</td>
                                    <td className="p_backcell"><input name="pass2" type="password" autoComplete="on" className="fld1 form-control"
                                                                      id="pass2" size="20" maxLength="40" />
                                        </td>
                                    <td  className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td valign="top" className="p_backcell">Address 1 *:</td>
                                    <td className="p_backcell">
                                        <textarea name="address_1" cols="35" rows="3" className="fld1 form-control"
                                                                         id="address_1"></textarea>
                                    </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td valign="top" className="p_backcell">Address 2:</td>
                                    <td className="p_backcell">
                                        <textarea name="address_2" cols="35" rows="3" className="fld1 form-control"
                                                                         id="address_2"></textarea>
                                    </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td valign="top" className="p_backcell">City *:</td>
                                    <td className="p_backcell">
                                        <input name="city" type="text" className="fld1 form-control"
                                                  id="city" />
                                    </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell">Postcode *:</td>
                                    <td className="p_backcell"><input name="postcode" type="text" className="fld1 form-control"
                                                                      id="postcode" size="10" maxLength="10" />
                                        </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell">Telephone *:</td>
                                    <td className="p_backcell"><input name="phone" type="text" className="fld1 form-control" id="phone"
                                                                      size="14" maxLength="14" />
                                    </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell">Fax :</td>
                                    <td className="p_backcell"><input name="fax" type="text" className="fld1 form-control" id="fax"
                                                                      size="20" maxLength="30" />
                                    </td>
                                    <td className="p_backcell"></td>
                                </tr>
                                <tr>
                                    <td className="p_backcell"></td>
                                    <td colSpan="2"
                                     className="p_backcell">* required fields</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" align="">
                                        <div className="my-2 d-flex justify-content-between w-100 p-2">
                                            <div className="d-inline mr-2">
                                                <input type="button" name="SubmitWillForm" id="SubmitWillForm"
                                                       className="butt_bac btn_2" value="Back" onClick={this.closeWillForm.bind(this)} />

                                            </div>
                                            <div className="d-inline ">
                                                <input type="button" onClick={this.validate.bind(this)} name="submit_img" className="butt_submi btn_3 last_submit px-2 py-1" value="Submit"/>
                                                <span className="spinner-border spinner-border-sm ml-1 mb-2 d-none" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mr-2" id="payment-gateway-alert" style={{display: this.state.displayNone}}>
                                            <div className="col-12 text-center">
                                                Please, wait till you are redirected to the payment gateway. Don't refresh the page or click any button.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </form>
                            <div className="row">
                                <div className="col-md-12 mt-3 text-center">
                                    <h6>Purchase any of our Will solutions <strong>NOW</strong> and you'll receive
                                        these <strong>EXCLUSIVE Special Offers</strong></h6>
                                </div>
                                <div className="col-md-6">
                                    <img src="images/book2.gif" alt="free book offer" />
                                </div>
                                <div className="col-md-6 mt-2">
                                    <p className="boldIt">"The Essential Guide to Inheritance Tax"</p>
                                    <p className="centerIt">(RRP £16.99)</p>
                                    <p className="boldIt">AND</p>
                                    <p className="boldIt">"The Essential Guide to Discretionary Trust Wills"</p>
                                    <p className="centerIt">(RRP £12.99)</p>
                                    <p className="boldIt">ABSOLUTELY FREE</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div valign="top">
                                <div align="center" className="time">
                                </div>
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                    <tr>
                                        <td height="10"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="pb-3">
                                            <img src="images/securepay.gif" alt="SecurePay - powered by UKDS" width="186" height="49" border="0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="5"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <p align="justify">
                                                Our SecurePay through UK Digital Solutions system has been developed by
                                                our team of security experts here at UK Digital Solutions, leaders in
                                                innovation and the latest technology. Our SecurePay payment gateway
                                                operates with extremely dependable and unshakeable military-strength
                                                encryption and the securest servers. Our SecurePay system has never been
                                                compromised. We are fully confident that this system is far safer than
                                                over-the-phone transactions.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="5"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <h6>Major Credit/Debit Cards we accept</h6>
                                            <img src="../images/cards.gif" alt="Major Credit/Debit Cards we accept"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="10"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">Please make Cheques &amp; POs to:</td>
                                    </tr>
                                    <tr>
                                        <td height="10"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext"><strong>"QD eForms"</strong></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <p align="justify" >For discretion, this
                                            is how the billing details will appear on your Credit / Debit Card
                                            Statement.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext policy-page">
                                            <p align="justify" >Please read
                                            our <Link to="disclaimer.html">disclaimer </Link>
                                            and <Link to="terms.html">terms &amp; conditions </Link>
                                            before purchasing your Clean Break pack. By clicking 'submit' you
                                            have agreed to our terms, as laid out in the disclaimer and terms &amp;
                                            conditions.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext policy-page">
                                            <Link to="terms.html#refund" className="primary">Refunds Policy</Link></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <p align="justify" >
                                            <strong>NOTE: </strong><font color="#FF0000">After payment
                                            authorisation, you will require
                                            your <strong>email</strong> and <strong>password</strong>
                                             to login to our website ... </font></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderNow;