import React from "react";
// import {Link} from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";


// $("#c_blog .blog_content").text(function (i, text) {
//   return text.length > 100 ? text.substr(0, text.lastIndexOf(' ', 20)) + '...' : text;
// });
$(document).ready(function(){

  $(".read_more").click(function(){
    alert("The paragraph was clicked.");
    console.log("clicked");
  });

  // $(".blog_content p.blog_content").text(function(index, currentText) {
  //   var maxLength = $(this).parent().attr('data-maxlength');
  //   if(currentText.length >= maxLength) {
  //     return currentText.substr(0, maxLength) + "...";
  //   } else {
  //     return currentText
  //   } 
  // });

  // var content = person.content;

  // content.replace(/^(.{11}[^\s]*).*/, "$1");
  // var str = $("#c_blog .blog_content").text()
  // var res = str.substring(0, 5);
  // console.log(res)
  //get first 5 chars

});

// import MetaTags from "react-meta-tags";
// import ReactDOM from 'react-dom';


// $(document).ready(function () {
    // const setErrorMessage = useState({ type: '', message: "" });
    
     class Blog extends React.Component {
        // constructor(props) {
        //   super(props);
          state = {
            persons: []
          }
        // }
        componentDidMount() {
        var setErrorMessage;
        axios
        .get("https://mobo.quickie-divorce.com/admin/api/list_post")
        .then(response => {
            const persons = response.data;
            console.log(persons);
            this.setState({ persons });
            // var setErrorMessage = 'test';//response.data[0];
            console.log(persons);
            // this.Blog();

        })
        .catch(function(error) {
            console.log(error);
        });
    }
        render() {
          return (
            <div className="row" id="c_blog">
              <div className="col-12 col-lg-9">
                <div className="row equal-cols">


              {
              this.state.persons
                    .map(person =>
                      
                      <div className="col-12 col-lg-4 col-md-6">
                      {/* <div className="d-flex"> */}
                       <div className="blog_box row equal-cols">
                         <div className="col-12 h_img">
                          <img src="images/logo-white.svg" alt="separation agreement" border="0" width="100" />
                         </div>
                        <div className="col-12">
                            <h2 className="blog_title" key={person.id}> {person.title} </h2>
                          </div>
                          {/* <p key={person.id}> {person.content} </p> */}
                          <div className="blog_content col-12" data-maxlength="20" dangerouslySetInnerHTML={{ __html: person.content }} />
                          {/* </div> */}
                          <div className="d-flex justify-content-end py-2 readmore col-12">
                            <Link href="#" id={person.sulg} className="read_more">Read More</Link>
                            {/* <button name="readmore1" id={person.slug}>Read More</button> */}
                          </div>
                       </div>
                    
                    


                      </div>
                  //  <div>{person.content}</div> 
                    
                    )
                }
                </div>
                </div>
                <div className="col-0 col-lg-3"></div>
            </div>
          );
        }
      }
// function Blog() 
// class UsingAxios extends Component
// {
//     const setErrorMessage = ({response}) => console.log(response.data[0]['id']);
//     // const yourComponent = ({response}) => console.log(response);

//     render(){
//         return (
//             <div id="details">
//                 <MetaTags>
//                     <title>Option 1 - Life Time Wills</title>
//                     <meta id="meta-description" name="description" content="" />
//                     <meta id="meta-keywords" name="keywords" content="" />
//                     <meta id="og-title" property="og:title" content="Option 1 - Life Time Wills" />
//                 </MetaTags>
//                 <h1 className="page_title">Blog{setErrorMessage}</h1>
                    
//             </div>
//             );
//     }
// }
export default Blog;