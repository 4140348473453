import React from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";
import AboutStage from "../../components/AboutStage";



function About() {
    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Wills" />
                </MetaTags>
                <div className="about_page" id="about_page"> 
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            {/* Title */}
                            <div className="index_title">
                                <h1 className="page_title">About Divorce</h1>
                            </div>
                            {/* Link */}
                            <div className="d-flex justify-content-end">
                                <p>To view this process 'at a glance', <Link className="link">click here</Link> </p>
                            </div>
                            {/* Stages */}
                            <div className="stages">
                                <div className="stage_1 stage">
                                    <h2><AboutStage title="Stage 1 - Application For Divorce"/></h2>
                                    <AboutStage content={ <>
                                        <p>To get a divorce in England and Wales, you need to show that you have been married for more than a year and that the marriage has “irretrievably broken down”. To do this you need to establish that you have grounds for divorce based on one of the following:</p>
                                        <ul>
                                            <li>
                                                <storng>Adultery.</storng>Your spouse has committed adultery and you find it intolerable to live together.
                                            </li>
                                            <li>
                                                <storng>Unreasonable Behaviour.</storng> Your spouse has behaved in such a way that you cannot reasonably be expected to live together.
                                            </li>
                                            <li>
                                                <storng>Two-Year Separation With Consent.</storng>You have been separated for 2 years and your spouse agrees to divorce.
                                            </li>
                                            <li>
                                                <storng>Five-Year Separation.</storng>You have been separated for 5 years.
                                            </li>
                                            <li>
                                                <storng>Desertion.</storng>Your spouse deserted you more than 2 years ago. We strongly recommend that desertion is avoided. If you want to base your divorce on desertion you may require a managed divorce.
                                            </li>
                                        </ul>
                                        <p>
                                            Depending on which option you choose, we will provide you with all the necessary documentation and guidance required to complete your Divorce Application quickly, easily, and with a minimum of stress and discomfort…
                                        </p>
                                    </>
                                    }  />
                                </div>
                                <div className="stage_2 stage">
                                <h2><AboutStage title="Stage 2 - Acknowledgement of Service"/></h2>
                                    <AboutStage content={ <>
                                        <p> 
                                            Once your Divorce Petition has been completed and sent to your local County Court, it will be checked by the Courts and issued to your husband/wife.
                                        </p>
                                        <p>
                                            In legal proceedings, you are known as the Petitioner and your spouse is known as the Respondent.
                                        </p>
                                        <p>
                                            Along with the Divorce documentation, the Respondent will also be sent instructions on how to complete the Acknowledgment of Service documentation.
                                        </p>
                                        <p>
                                            Once they have completed this simple document and returned it to the Court, your divorce will then progress to Stage 3…
                                        </p>
                                    </>
                                    }  />
                                </div>
                                <div className="stage_3 stage">
                                <h2><AboutStage title="Stage 3 - Application for Decree Nisi"/></h2>
                                    <AboutStage content={ <>
                                        <p>In effect, an application for a Decree Nisi is simply the process of asking the Court to place your Divorce Petition and supporting documentation before a Judge in order for him or her to consider whether to grant your divorce.</p>

                                        <p>You can choose from the following options:</p>

                                        <p><strong>Option 1</strong> – Download and complete all the paperwork yourself. We will provide you with all the documentation required plus all the information on how to complete the application for your Decree.</p>

                                        <p><strong>Option 2</strong> – You provide the minimum of details and we will complete all the necessary documentation you’ll need for your Decree Nisi online at <strong>NO EXTRA COST</strong>.</p>
                                        
                                        <p>
                                            All our documentation is written and approved by a team of highly experienced divorce lawyers with a wealth of experience in this field. Our simple solutions will help you progress easily to the final stage of your divorce…
                                        </p>
                                    </>
                                    }  />
                                </div>
                                <div className="stage_4 stage">
                                <h2><AboutStage title="Stage 4 - Application for Decree Absolute"/></h2>
                                    <AboutStage content={ <>
                                        <p>
                                            After the declaration of your Decree Nisi, you will need to wait a further six weeks and one day, after which you are entitled to apply for a Decree Absolute.
                                        </p>

                                        <p>
                                            Following submission of this application to the County Court, the Court will then declare your Decree Absolute. This means that your marriage would have been dissolved.</p>
                                        <p>Although this process may seem complicated, remember:</p>
                                        
                                        <p>
                                            <strong>Option 1 - We will provide you with all the documentation required</strong> to complete the application for your Decree Absolute. The authors of our quick and easy-to-follow guides have helped thousands of clients with their divorces.
                                        </p>
                                        <p>
                                            <strong>Option 2 - We can complete your divorce application for you.</strong> Simply provide us with all your relevant details and we’ll do the rest.
                                        </p>
                                    </>
                                    }  />
                                </div>
                            </div>
                        </div>
                        <ContentRight />
                    </div>
                </div>
            </div>
        );
}

export default About;