import React from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import MetaTags from "react-meta-tags";

function goBackWard(e, go){
    e.preventDefault();
    if(go){
        window.location.href = "javaScript:history.back(1)"; //eslint-disable-line
    }
}

function Disclaimer() {
        return (
            <div id="details">
                <MetaTags>
                    <title>Disclaimer - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="Disclaimer - Life Time Wills" />
                </MetaTags>
                <h4>Disclaimer</h4>
                <p align="justify"> Use of the LifeTime Wills online service is subject to the following
                    terms and conditions:</p>
                <p><strong>1) Jurisdiction</strong></p>
                <ol type="a">
                    <li>This agreement is covered by the Laws of England and Wales. Any rights
                        not expressly granted herein are reserved. These terms and conditions
                        shall not affect your statutory rights as a consumer
                    </li>

                </ol>
                <p><strong>2) Limitations</strong></p>
                <ol start="2" type="a">
                    <li>LifeTime Wills does not accept liability or responsibility for
                        any additional advice that clients seek in conjunction with their documentation
                        and/or advice.
                    </li>

                </ol>
                <p><strong>3) General</strong></p>
                <ol start="3" type="a">
                    <li>LifeTime Wills does not accept responsibility or liability for any
                        losses incurred by its users. <br /><br />
                    </li>
                    <li>If a Court of Law later decides that any part of this disclaimer
                        is not reasonable or cannot be enforced for any reason, that decision
                        will not affect or relate to any other parts of the disclaimer which
                        will be enforced or continue to be enforceable in any event
                    </li>
                </ol>
                <p><strong>Privacy Policy</strong></p>
                <p align="justify"> LifeTime Wills is committed to protecting the identity and privacy of
                    our users. We will only use information collected in the process of transactions
                    in a strictly lawful manner in accordance with the Data Protection Act
                    1998.</p>
                <p align="justify">LifeTime Wills will only use client emails to contact clients once the
                    transaction has been completed in order to provide specific information
                    with regard to their service. Email addresses will not be provided to
                    any third party.</p>
                <p align="justify">Users should be aware that LifeTime Wills retains no information relating
                    to client’s credit card details. This information is retained by
                    the Merchant Service Provider.
                </p>
                <p><strong>Modifications</strong></p>
                <p align="justify"> LifeTime Wills reserves the right to change the terms and conditions
                    of business and disclaimers relating to our website service. </p>

                <div className="w-100 d-flex justify-content-between">
                    <Link to="/" className="fleft btn_1 d-flex align-items-center" onClick={(e) => goBackWard(e, 1)}>
                        <span className="b_inner">&#129144; Back</span>
                    </Link>
                    {/* <Link className="btn_1" to="/example-form.html" >
                        <span className="b_inner">Next &#10132;</span>

                    </Link> */}
                </div>

                {/* eslint-disable-line */}
                {/* <a href="/#" onClick={(e) => goBackWard(e, 1)}> 
                    <img src="/images/back.gif" name="Image12" border="0" alt="back" />
                </a> */}
            </div>
        );
}

export default Disclaimer;