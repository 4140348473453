import React from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";

function ContactUs(props) {
    return (
        <div id="details">
            <MetaTags>
                <title>About - Life Time Wills</title>
                <meta id="meta-description" name="description" content="" />
                <meta id="meta-keywords" name="keywords" content="" />
                <meta id="og-title" property="og:title" content="About - Life Time Wills" />
            </MetaTags>
            <div className="contact_us" id="contact_us"> 
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-12">
                        {/* Title */}
                        <div className="index_title">
                            <h1 className="page_title">Confused About Divorce?</h1>
                        </div>
                        {/* Link */}
                        <div className="head_para">
                            <p className="pb-2"> -  Last year, over 22,500 people in the UK called Quickie Divorce for help and advice initiating their uncontested divorce. </p>
                            <p className="pb-4"> -  Our team of experts can give guidance on all matters relating to our divorce process, and can also help you choose which service is right for you.</p>
                        </div>
                        <div className="call_sec">
                            <div className="row equal-cols">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="req_callback bg_gray p-4">
                                        <div className="title_section d-flex pb-2">
                                            <img src="/images/services-download.svg" alt="" width="30"/>
                                            <h3 className="psub_title pl-2">Request a callback</h3>
                                        </div>
                                        <div className="con_section b_line pb-3 ">
                                            <p>
                                            At Quickie Divorce, we have the longest opening hours of any online divorce company. However, our lines are currently closed. We are more than happy to give you a confidential call back at a day and time that is convenient for you. Simply fill out the secure form below and one of our team of experts will be in touch at a time that suits you. Security, discretion and confidentiality are really important to us, so your details will never be passed on to any third party
                                            </p>
                                        </div>
                                        <div className="form_section pt-3">
                                        <form>
                                            <div class="form-group">
                                                <label for="name">Your Name*</label>
                                                <input type="text" class="form-control" id="name" checked/>
                                            </div>
                                            <div class="form-group">
                                                <label for="emailid">Your Email*</label>
                                                <input type="email" class="form-control" id="emailid" aria-describedby="emailHelp" checked/>
                                            </div>
                                            <div class="form-group">
                                                <label for="number">Phone Number*</label>
                                                <input type="number" class="form-control" id="number" checked/>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleFormControlTextarea1">Your Note*</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
                                            </div>
                                            <p className="pb-3 text-left" >Calls may be recorded for training and monitoring purposes</p>
                                            <button type="submit" class="btn_1">Submit</button>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mt-3 mt-lg-0 mt-md-0">
                                    <div className="want_talk bg_gray p-4">
                                        <div className="title_section d-flex pb-2 b_line">
                                            <img src="/images/services-download.svg" alt="" width="30"/>
                                            <h3 className="psub_title pl-2">Want to talk to us</h3>
                                        </div>
                                        <div className="con_section pt-3">
                                            <p className="pb-3">For free, impartial advice - call us now.</p>
                                            <h4 className="pb-3 mb-0 page_title fw_700">0800 803 0813</h4>
                                            <div className="details lh_30">
                                                <div className="row">
                                                    <div className="col">
                                                        <p>MONDAY</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>8AM-9PM</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p>TUESDAY</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>8AM-9PM</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p>WEDNESDAY</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>8AM-9PM</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p>THURSDAY</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>8AM-9PM</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p>FRIDAY</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>8AM-5.30PM</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p>SATURDAY</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>10AM-2PM</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p>SUNDAY</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>CLOSED</p>
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="call_btm_sec pt-3">
                                <div className="row equal-cols">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="fax_us bg_gray p-4">
                                            <div className="title_section d-flex pb-2 b_line">
                                                <img src="/images/services-download.svg" alt="" width="30"/>
                                                <h3 className="psub_title pl-2">FAX us</h3>
                                            </div>
                                            <div className="details pt-3">
                                                <p>FAX:</p>
                                                <p>0800 803 0813</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 mt-3 mt-lg-0 mt-md-0">
                                        <div className="email_us bg_gray p-4">
                                            <div className="title_section d-flex pb-2 b_line">
                                                <img src="/images/services-download.svg" alt="" width="30"/>
                                                <h3 className="psub_title pl-2">Email us</h3>
                                            </div>
                                            <div className="details pt-3">
                                                <p>General Enquiries</p>
                                                <a href="mailto:enquiries@quickie-divorce.com">enquiries@quickie-divorce.com</a>
                                                <p>Comment & Suggestions</p>
                                                <a href="webmaster@quickie-divorce.com">webmaster@quickie-divorce.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 mt-3 mt-lg-0 mt-md-3 mb-3 mb-lg-0 mb-md-0">
                                        <div className="write_us bg_gray p-4">
                                            <div className="title_section d-flex pb-2 b_line">
                                                <img src="/images/services-download.svg" alt="" width="30"/>
                                                <h3 className="psub_title pl-2">Write to us</h3>
                                            </div>
                                            <div className="details pt-3">
                                                <p>Quickie Divorce UK</p>
                                                <p>Fields House</p>
                                                <p>18-21 Old Field Road</p>
                                                <p>Pencoed</p>
                                                <p>CF35 5LJ</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            


                        </div>
                    </div>
                    <ContentRight />
                </div>
            </div>
        </div>
    );
}

export default ContactUs;