import React from "react";
import {Link} from "react-router-dom";

function ContentRight() {
    return (
        <div className="col-lg-3 col-md-3 col-12">
        <div className="box-container_right content_right d-flex flex-column align-items-lg-end align-items-md-end align-items-center">
            <div className="box">
                <Link to="/option1.html" title="Wills option 1">
                    <img src="/images/side-free-support.png" 
                        alt="option 1"/>
                </Link>
            </div>

            <div className="box">
                <Link to="/option2.html" title="Wills option 2">
                    <img src="/images/side-quick-online-sidebar.png"
                        alt="option 2"/>
                </Link>
            </div>

            <div className="box">
                <Link to="/option3.html" title="Wills option 3">
                    <img src="/images/side-personalised-service-sidebar.png" alt="option 3"/>
                </Link>
            </div>

            <div className="box">
                <Link to="/about-wills3.html" title="Storage Option">
                    <img src="/images/side-plus-service-sidebar.png" alt="storage"/>
                </Link>
            </div>
        </div>
        
    </div>
    );
}

export default ContentRight;