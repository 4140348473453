import React from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import ContentRight from "../../components/ContentRight";



function Traking() {
    return (
            <div id="details">
                <MetaTags>
                    <title>About - Life Time Wills</title>
                    <meta id="meta-description" name="description" content="" />
                    <meta id="meta-keywords" name="keywords" content="" />
                    <meta id="og-title" property="og:title" content="About - Life Time Wills" />
                </MetaTags>
                <div className="about_page" id="about_page"> 
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            {/* Title */}
                            <div className="index_title">
                                <h1 className="page_title">Quickie Divorce is the Only Online Provider With 24/7 Case Management Tracking</h1>
                            </div>
                            <div className="contents">
                                <p>At Quickie Divorce, we understand that our clients have jobs and responsibilities, and may not be able to contact us during opening hours.</p>
                                <p>To help with this, we have developed a cutting edge case management software package that allows clients to log on through our website 24/7 and:</p>
                                <ul>
                                    <li>Track the status of their divorce</li>
                                    <li>Set up automated emails and text alerts for ‘change of status’ events</li>
                                    <li>Download copies of completed petitions and documents</li>
                                    <li>Download copies of letters and emails</li>
                                    <li>Leave messages for support staff</li>
                                    <li>View the log file of their divorce to verify what work has been completed</li>
                                </ul>
                                <p>Since its launch, this service has consistently been cited by our clients as one of the most useful facilities available to them during the divorce process.</p>

                                <div className="page-img py-2">
                                    <img src="/images/services-download.svg" width="60" alt="services" />
                                </div>

                                <p>No other online divorce provider offers this facility, because no other online divorce provider has invested as much time, effort and money in ensuring that the divorce process is as quick, simple and affordable as possible for its clients.</p>
                            </div>
                        </div>
                        <ContentRight />
                    </div>
                </div>
            </div>
        );
}

export default Traking;